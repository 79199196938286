import {WithProductGroupSelector} from '@mfb/cookbook';
import * as React from 'react';

import {
  ProductGroupHeroProps,
  PureProductGroupHero,
} from './PureProductGroupHero';

const ProductGroupHeroWithSelector = WithProductGroupSelector(
  PureProductGroupHero,
  true
);
const ProductGroupHeroWithoutSelector = WithProductGroupSelector(
  PureProductGroupHero,
  false
);

export const ProductGroupHero: React.FC<ProductGroupHeroProps> = props => {
  const Component = props.showSelector
    ? ProductGroupHeroWithSelector
    : ProductGroupHeroWithoutSelector;

  return <Component {...props} />;
};
