import {Dictionary} from '@mfb/lego';

import ResponsiveImageSetContentModel from '../contentful/responsiveImageSet/ResponsiveImageSetContentModel';

export const defaultImageSet: Dictionary<string> = {
  small: '',
  medium: '',
  large: '',
};

export const mapContentfulImageSetToDictionary = (
  imageSet: ResponsiveImageSetContentModel
) =>
  imageSet
    ? {
        small: imageSet.small && imageSet.small.file.url,
        medium: imageSet.medium && imageSet.medium.file.url,
        large: imageSet.large && imageSet.large.file.url,
      }
    : defaultImageSet;
