import * as React from 'react';
import ReactMarkdown from 'react-markdown';

const defaultHeading = ReactMarkdown.renderers
  .heading as ReactMarkdown.Renderer<any>;

export const getRenderers = () => ({
  heading: (props: any) => {
    let className;
    switch (props.level) {
      case 1:
        className = 'display-3';
        break;
      case 2:
        className = 'display-4';
        break;
    }

    return React.cloneElement(defaultHeading(props), {className});
  },
});
