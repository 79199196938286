import * as React from 'react';

import {Terms} from '../../components/terms/Terms';
import {TermsSectionModel} from '../../components/terms/TermSectionModel';
import {TermsSubsectionModel} from '../../components/terms/TermsSubsectionModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {TermsAndConditionsSectionContentModel} from './TermsAndConditionsSectionContentModel';

const ContentfulTermsAndConditionsSection: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as TermsAndConditionsSectionContentModel;

  const sections = model.terms.map<TermsSectionModel>(s => ({
    subsections: s.subsections.map<TermsSubsectionModel>(ss => ({
      id: ss.id,
      title: ss.title,
      text:
        (ss.childContentfulTermsSubsectionTextTextNode &&
          ss.childContentfulTermsSubsectionTextTextNode.text) ||
        '',
    })),
    title: s.title,
    id: s.id,
  }));

  return <Terms id={model.id} title={model.title} sections={sections} />;
};

export default ContentfulTermsAndConditionsSection;
