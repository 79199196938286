import {QueryParams} from "./ContentfulBagSelector";


export const handleCampaignToggleDefaultState = (campaignCode: string) => {
    if (typeof window !== `undefined`) {
        try {
            const params = new URLSearchParams(window.location.search);
            const existingCampaignCode = params.get(QueryParams.campaignCode);

            return !!(existingCampaignCode && existingCampaignCode === campaignCode);
        } catch (e) {
            console.error(e);
        }
    }
    return false;
}

export const handleCampaignToggle = (campaignCode: string, isChecked: boolean) => {
    if (typeof window !== `undefined`) {
        try {
            const url = new URL(window.location.origin);
            const params = new URLSearchParams(window.location.search);

            if (isChecked) {
                //override existing campaign code
                params.delete(QueryParams.campaignCode);
                params.append(QueryParams.campaignCode, campaignCode);
            } else if (params.has(QueryParams.campaignCode)) {
                params.delete(QueryParams.campaignCode);
            }

            params.forEach((value, key) => {
                url.searchParams.append(key, value);
            });

            window.history.pushState({}, '', url);
        } catch (e) {
            console.error(e);
        }
    }
}

export const removeCampaignCodeFromUrl = (campaignCode: string) => {
    if (typeof window !== `undefined`) {

        try {
            const url = new URL(window.location.origin);
            const params = new URLSearchParams(window.location.search);

            const existingCampaignCode = params.get(QueryParams.campaignCode);

            if (existingCampaignCode && existingCampaignCode === campaignCode) {
                params.delete(QueryParams.campaignCode);
            }

            params.forEach((value, key) => {
                url.searchParams.append(key, value);
            });

            window.history.pushState({}, '', url);
        } catch (e) {
            console.error(e);
        }
    }
}

