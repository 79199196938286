import {ResponsiveImage} from '@mfb/lego';
import * as React from 'react';

import ClickableDiv from '../../components/ClickableDiv';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {getContentfulLink} from '../link/LinkContentModel';
import {mapResponsiveImageSetContentModelToResponsiveImageProps} from '../responsiveImageSet/ResponsiveImageSetContentModel';
import DoubleMerchSlotContentModel from './DoubleMerchSlotContentModel';

const ContentfulDoubleMerchSlot: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const {
    name,
    secondName,
    firstLink,
    firstResponsiveImageSet,
    secondLink,
    secondResponsiveImageSet,
  } = baseModel as DoubleMerchSlotContentModel;

  const firstImageSet =
    firstResponsiveImageSet &&
    mapResponsiveImageSetContentModelToResponsiveImageProps(
      firstResponsiveImageSet
    );

  const secondImageSet =
    secondResponsiveImageSet &&
    mapResponsiveImageSetContentModelToResponsiveImageProps(
      secondResponsiveImageSet
    );

  return firstImageSet && secondImageSet ? (
    <>
      <div
        className="my-2 mx-lg-2"
        data-category="merchandising"
        data-action="click-double-merch-slot"
        data-label={`${name} - first-double-merch-slot`}
      >
        <ClickableDiv link={getContentfulLink(firstLink)}>
          <ResponsiveImage {...firstImageSet} />
        </ClickableDiv>
      </div>
      <div
        className="my-2 mx-lg-2"
        data-category="merchandising"
        data-action="click-double-merch-slot"
        data-label={`${secondName} - second-double-merch-slot`}
      >
        <ClickableDiv link={getContentfulLink(secondLink)}>
          <ResponsiveImage {...secondImageSet} />
        </ClickableDiv>
      </div>
    </>
  ) : null;
};

export default ContentfulDoubleMerchSlot;
