import {Dictionary} from '@mfb/lego';
import * as React from 'react';

import {fallbackImages} from '../../components/FallbackImageSet';
import {ImageModel} from '../../components/ImageModel';
import Picture from '../../components/Picture';
import {getImageModel} from '../asset/AssetContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import ResponsiveImageSetContentModel from './ResponsiveImageSetContentModel';

const ContentfulResponsiveImageSet: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as ResponsiveImageSetContentModel;

  const image: Dictionary<ImageModel> = model
    ? {
        small: getImageModel(model.small),
        medium: getImageModel(model.medium),
        large: getImageModel(model.large),
      }
    : fallbackImages;

  return <Picture imageSet={image} alt={model.alternateText} />;
};

export default ContentfulResponsiveImageSet;
