import React from 'react';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import SubscriptionPlanContentModel from './SubscriptionPlanContentModel';
import {
  SubscriptionPlan,
  ValueProposition,
  SubscriptionPlanCard,
  SvgSubscriptionPlanCross,
  SvgSubscriptionPlanTick,
  SvgSubscriptionPlanGift,
} from '@mfb/lego';

const regex = new RegExp(
  '(?<icon>({TICK}|{CROSS}|{GIFT}))(?<transform>(?:{BOLD})?)(?<proposition>[a-zA-Z0-9-/].+)'
);

const ContentfulSubscriptionPlan: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as SubscriptionPlanContentModel;

  const buildValuePropositions = (rawValuePropositions: Array<string>) => {
    const mapIcon = (icon: string) =>
      ({
        '{TICK}': <SvgSubscriptionPlanTick />,
        '{CROSS}': <SvgSubscriptionPlanCross />,
        '{GIFT}': <SvgSubscriptionPlanGift />,
      }[icon]);

    const valuePropositions: Array<ValueProposition> = [];

    rawValuePropositions.map(c => {
      const match = regex.exec(c);

      if (match && match.groups) {
        const {icon, transform, proposition} = match.groups;

        try {
          valuePropositions.push({
            prefixIcon: mapIcon(icon),
            proposition: proposition,
            isBold: transform ? true : false,
          });
        } catch (e) {
          console.error(`Invalid value proposition for ${c} ${e.message}`);
        }
      }
    });

    return valuePropositions;
  };

  const navigateToRelativePathWithQueryParams = (relativePath?: string): void => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const queryParams = url.searchParams.toString();
    if (relativePath && queryParams !== '') {
      window.location.href = `${relativePath}&${queryParams}`;
    } else if (relativePath) {
      window.location.href = relativePath;
    }
  };

  const subscription: SubscriptionPlan = {
    id: model.id,
    ribbonText: model.ribbonText,
    header: model.title,
    subheader: model.description,
    price: model.price,
    pricePrefix: model.pricePrefix,
    pricePostfix: model.pricePostfix,
    valuePropositions: buildValuePropositions(model.valuePropositions),
    action: {
      label: model.actionButton.label || '',
      onAction: () => {
        navigateToRelativePathWithQueryParams(model.actionButton.link.relativePath);
      },
    },
    sortOrder: model.sortOrder,
    sortOrderMobile: model.sortOrderMobile,
  };

  return (
    <SubscriptionPlanCard
      subscription={subscription}
      header={<SubscriptionPlanCard.Header />}
      info={
        <SubscriptionPlanCard.Info>
          <SubscriptionPlanCard.Price />
          <SubscriptionPlanCard.ValuePropositions />
        </SubscriptionPlanCard.Info>
      }
      action={<SubscriptionPlanCard.Action />}
      variant={model.isHero ? 'hero' : 'default'}
    />
  );
};
export default ContentfulSubscriptionPlan;
