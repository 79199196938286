import * as React from 'react';

import {
  CallToActionCard,
  CallToActionCardProps,
} from '../../components/callToActionCard/CallToActionCard';
import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {ContentfulCallToActionCardContentModel} from './ContentfulCallToActionCardContentModel';

const ContentfulCallToActionCardSection: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as ContentfulCallToActionCardContentModel;

  const props: CallToActionCardProps = {
    id: model.id,
    title: model.title,
    button: model.callToAction && mapActionButton(model.callToAction),
    description: model.description,
    image: {
      src: model.image.file.url,
      alt: model.image.description,
      id: model.image.id,
      caption: model.image.description,
    },
  };

  return <CallToActionCard {...props} />;
};

export default ContentfulCallToActionCardSection;
