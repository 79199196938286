import {LegoBaseTheme} from '@mfb/lego';
import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

const StyledRatingsWidgetMedium = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  height: 67px;
  pointer-events: none;
`;

const StyledRatingsWidgetSmall = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
  height: 61px;
  pointer-events: none;
`;

export const Ratings: React.FunctionComponent = () => {
  const [scriptMedium, setScriptMedium] = React.useState('');
  const [scriptSmall, setScriptSmall] = React.useState('');

  React.useEffect(() => {
    if (!document.getElementById('ratings-widget-medium')?.hasChildNodes()) {
      setScriptMedium(`
        reviewsBadgeRibbon("ratings-widget-medium", {
          store: "myfoodbag-co-nz",
          size: "medium",
        });
          `);
    }

    if (!document.getElementById('ratings-widget-small')?.hasChildNodes()) {
      setScriptSmall(`
        reviewsBadgeRibbon("ratings-widget-small", {
          store: "myfoodbag-co-nz",
          size: "small",
        });
      `);
    }
  }, []);
  return (
    <>
      <Helmet>
        <script>{scriptMedium}</script>
        <script>{scriptSmall}</script>
      </Helmet>
      <div className="d-flex w-100 justify-content-center flex-wrap">
        <StyledRatingsWidgetMedium id="ratings-widget-medium"></StyledRatingsWidgetMedium>
        <StyledRatingsWidgetSmall id="ratings-widget-small"></StyledRatingsWidgetSmall>
      </div>
    </>
  );
};
