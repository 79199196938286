import * as React from 'react';

import BaseContentModel from './BaseContentModel';
import ComponentConfig from './ComponentConfig';
import {ContentfulBaseProps} from './ContentfulBaseProps';
import {GatsbyNavProps} from './GatsbyNavProps';
import PageSettingsContentModel from './pageSettings/PageSettingsContentModel';

const contentfulComponents: any = {};

for (let i = ComponentConfig.length - 1; i >= 0; i--) {
  contentfulComponents[
    ComponentConfig[i].name
  ] = require(`${ComponentConfig[i].path}`).default;
}

const renderContentfulSingle = (
  ps: BaseContentModel,
  pageSettings: PageSettingsContentModel | undefined | null,
  navProps: GatsbyNavProps | undefined | null,
  position?: number
) => {
  // eslint-disable-next-line no-prototype-builtins
  if (contentfulComponents.hasOwnProperty(ps.__typename)) {
    const Component = contentfulComponents[ps.__typename];

    ps.position = position;
    const props: ContentfulBaseProps = {
      baseModel: ps,
    };

    if (pageSettings) {
      props.pageSettings = pageSettings;
    }

    if (navProps) {
      props.navProps = navProps;
    }

    const uniqueish = Math.random() * 9999;

    return (
      <Component key={`${ps.__typename}-${ps.id}-${uniqueish}`} {...props} />
    );
  }

  return null;
};

const renderContentful = (
  models: Array<BaseContentModel> | undefined | null,
  pageSettings: PageSettingsContentModel | undefined | null,
  navProps: GatsbyNavProps | undefined | null
) => {
  return (
    models &&
    models
      .map((m, index) =>
        renderContentfulSingle(m, pageSettings, navProps, index + 1)
      )
      .filter(c => c)
  );
};

export default renderContentful;
