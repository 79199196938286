import {ResponsiveImageProps} from '@mfb/lego';

import AssetContentModel from '../asset/AssetContentModel';
import BaseContentModel from '../BaseContentModel';

export default interface ResponsiveImageSetContentModel
  extends BaseContentModel {
  alternateText: string;
  small: AssetContentModel;
  medium?: AssetContentModel;
  large?: AssetContentModel;
  id: string;
}

export const mapResponsiveImageSetContentModelToResponsiveImageProps = (
  imageSet: ResponsiveImageSetContentModel
): ResponsiveImageProps => ({
  src: imageSet.small.file.url,
  mdSrc: imageSet.medium && imageSet.medium.file.url,
  lgSrc: imageSet.large && imageSet.large.file.url,
});
