import { fetchFromWebsiteAgent } from "./fetchData";
import { ApimClientConfiguration, NonPromotedCampaignsResponse, WebsiteApiClient } from "./WebsiteApiClient";

export const fetchNonPromotedCampaigns = async (campaignCode: string): Promise<
  NonPromotedCampaignsResponse | undefined
> => {
  const config = new ApimClientConfiguration();
  config.setApiKey(process.env.GATSBY_WEBSITE_API_KEY as string, '', '')
  const client = new WebsiteApiClient(
    config,
    process.env.GATSBY_WEBSITE_API_URL,
    fetchFromWebsiteAgent
  );
  const campaignResponse = await client.campaignBycode(campaignCode);

  return campaignResponse;
};