import {HeroCard, HeroCardType, mapAlignment} from '@mfb/lego';
import * as React from 'react';

import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {getImageModel} from '../asset/AssetContentModel';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import HeroCardContentModel from './HeroCardContentModel';

export const mapHeroCardType = (str?: string): HeroCardType | undefined =>
  HeroCardType[str as keyof typeof HeroCardType];

const ContentfulHeroCard: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as HeroCardContentModel;

  return (
    <HeroCard
      title={model.title}
      image={getImageModel(model.image)}
      description={model.description}
      color={getColor(model.color)}
      type={mapHeroCardType(model.cardType) || HeroCardType.Small}
      button={model.button && mapActionButton(model.button)}
      alignment={mapAlignment(model.alignment)}
    />
  );
};

export default ContentfulHeroCard;
