import {ProductModel} from '@mfb/cookbook';
import {isNotNullOrUndefined} from '@mfb/lego';

import {RecipeSelectionMode} from '../../../gatsbyLib/data/CoreApiClient';

export const allowsRecipeSelection = (
  recipeSelectionMode: RecipeSelectionMode
) => {
  switch (recipeSelectionMode) {
    case RecipeSelectionMode.Single:
      return true;
    case RecipeSelectionMode.Multiple:
      return true;
    default:
      return false;
  }
};

export const getProductSelectEventPath = (
  extraPath: string,
  isPrimary: boolean,
  product?: ProductModel,
  addQueryParamOnContinue?: string
): string => {
  if (!isPrimary) {
    return extraPath;
  }

  if (addQueryParamOnContinue && isNotNullOrUndefined(product)) {
      try {
        const url = new URL(product.cartLink || `${window.location.origin}${product.orderLink}`);
        const params = new URLSearchParams(addQueryParamOnContinue);
        params.forEach((value, key) => {
          url.searchParams.append(key, value);
        });

        if(product.cartLink){
          return url.href;
        }else if(product.orderLink){
          //relative url
          return `${url.pathname}${url.search}`;
        }
      } catch (ex) {
        console.error(ex);
      }
  }
  if (isNotNullOrUndefined(product)) {
    return product.cartLink || product.orderLink;
  }

  return '/';
};
