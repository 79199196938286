import * as React from 'react';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import InnerHTML from 'dangerously-set-html-content';
import RawHtmlRenderModel from './ContentfulRawHtmlRenderModel';
import styled from 'styled-components';

const RenderHtml = styled(InnerHTML)`
  width: 100%;
`;

const ContentfulRawHtmlRender: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as RawHtmlRenderModel;

  return <RenderHtml html={model.html?.html ?? '<div></div>'} />;
};

export default ContentfulRawHtmlRender;
