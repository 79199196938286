import {graphql, navigate, StaticQuery} from 'gatsby';
import * as React from 'react';

import {ProductCartWrapper} from '../../components/cart/ProductCartWrapper';
import {useSafariVHPolyFix} from '../../components/useSafariVHPolyFix';
import {getNodes} from '../../customGraphQlModels/Edges';
import {ProductDbModel} from '../../customGraphQlModels/ProductDbModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {ProductContentModel} from '../product/ProductContentModel';
import {getSkuFromQueryParams} from './getSkuFromQueryParams';
import {mergeToCartProducts} from './mergeToCartProducts';
import {ProductCartQueryModel} from './ProductCartQueryModel';
import CartContentModel from './ProductCartSectionContentModel';

interface PureProps {
  title?: string;
  defaultSku?: string;
  allDbProducts: Array<ProductDbModel>;
  allContentfulProducts: Array<ProductContentModel>;
  gatewayUrl: string;
}

export const PureContentfulProductCartSection: React.FC<PureProps> = ({
  title,
  defaultSku,
  allDbProducts,
  allContentfulProducts,
  gatewayUrl,
}) => {
  useSafariVHPolyFix();

  const [sku, setSku] = React.useState<string>();

  React.useEffect(() => {
    const queryParamSku = getSkuFromQueryParams();
    if (queryParamSku) {
      setSku(queryParamSku);
    } else if (defaultSku) {
      setSku(defaultSku);
    } else {
      console.error('No sku loaded', window.history.state);
      setSku(undefined);
      // navigate('/', {replace: true});
    }
  }, [defaultSku]);

  if (!sku) {
    return null;
  }

  const configuredItems = mergeToCartProducts(
    sku,
    allDbProducts,
    allContentfulProducts
  );

  const orderLink = `${gatewayUrl}/order/${sku}`;

  return (
    <ProductCartWrapper
      items={configuredItems}
      orderLink={orderLink}
      title={title}
      gatewayUrl={gatewayUrl}
    />
  );
};

const ContentfulProductCartSection: React.FC<ContentfulBaseProps> = ({
  navProps,
  baseModel,
}) => (
  <StaticQuery
    query={graphql`
      query {
        allProductDb(filter: {sku: {ne: "default"}}) {
          edges {
            node {
              ...ProductDbFragment
            }
          }
        }
        allContentfulProduct {
          edges {
            node {
              ...ProductFragment
            }
          }
        }
        site {
          ...SiteMetadataFragment
        }
      }
    `}
    render={(data: ProductCartQueryModel) => {
      const model = baseModel as CartContentModel;

      const allDbProducts = getNodes(data.allProductDb);
      const allContentfulProducts = getNodes(data.allContentfulProduct);

      return (
        <PureContentfulProductCartSection
          defaultSku={navProps && navProps.sku}
          title={model.title}
          allDbProducts={allDbProducts}
          allContentfulProducts={allContentfulProducts}
          gatewayUrl={data.site.siteMetadata.gatewayUrl}
        />
      );
    }}
  />
);

export default ContentfulProductCartSection;
