import {breakpoints, ResponsiveVideoEmbed} from '@mfb/lego';
// import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';

import {ContentfulBaseProps} from '../ContentfulBaseProps';
import ContentfulVideoEmbedContentModel from './VideoEmbedModel';
import {buildVideoUrl} from './VideoSource';

const ContentfulVideoEmbed: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as ContentfulVideoEmbedContentModel;

  return (
    <div
      className="flex-grow-1"
      style={{maxWidth: breakpoints.large, margin: '0 auto'}}
    >
      <ResponsiveVideoEmbed
        src={buildVideoUrl(model.source, model.videoId)}
        allowFullScreen
      />
    </div>
  );
};

export default ContentfulVideoEmbed;
