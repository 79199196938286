import {
  AvailableFrequency,
  LinkProps,
  ProductGroupProps,
  ProductGroupSelectorOptions,
  ProductModel,
  ProductType,
  StockActionType,
  useStock,
} from '@mfb/cookbook';
import {isNotNullOrUndefined} from '@mfb/lego';
import {graphql, StaticQuery} from 'gatsby';
import {first} from 'lodash';
import * as React from 'react';

import {ProductGroupHero} from '../../components/productGroupHero/ProductGroupHero';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {getProductModel} from '../product/getProductModel';
import {AllProductsQueryModel} from '../productCard/AllProductsQueryModel';
import {getProductGroupModel} from '../productGroup/getProductGroupModel';
import {getProductSelectEventPath} from '../productGroup/getProductSelectEventPath';
import {ProductGroupHeroContentModel} from './ProductGroupHeroContentModel';

type Props = ContentfulBaseProps & AllProductsQueryModel;

const PureContentfulProductGroupHero: React.FC<Props> = ({
  baseModel,
  site,
  pageSettings,
  allProductDb,
}) => {
  const model = baseModel as ProductGroupHeroContentModel;
  const [, dispatch] = useStock();

  const [products] = React.useState<Array<ProductModel>>(() =>
    model.productGroup.products
      .map(p => getProductModel(p, allProductDb, site.siteMetadata.gatewayUrl))
      .filter(isNotNullOrUndefined)
  );

  const firstProduct = first(products);

  const availableFreq = firstProduct && firstProduct.availableFrequency;

  React.useEffect(() => {
    if (availableFreq && availableFreq === AvailableFrequency.OneOff) {
      products.forEach(p =>
        dispatch({type: StockActionType.registerOneOff, sku: p.sku})
      );
    }
  }, [availableFreq, dispatch, products]);

  if (!firstProduct) {
    return null;
  }

  const isPrimary = firstProduct.productType === ProductType.Primary;
  const productSelectEvent: LinkProps = {
    generateLink: (sku: string, addQueryParamOnContinue?: string) => {
      const product = products.find(p => p.sku === sku);
      const extraPath = `${site.siteMetadata.accountUrl}/manage/subscriptions/extras/addbysku/${sku}`;

      return getProductSelectEventPath(
        extraPath,
        isPrimary,
        product,
        addQueryParamOnContinue
      );
    },
  };

  const productGroupModel = getProductGroupModel(model.productGroup, products);

  const productGroupProps: ProductGroupProps & ProductGroupSelectorOptions = {
    ...productGroupModel,
    id: model.id,
    addQueryParamOnContinue: model.addQueryParamOnContinue,
    preselectedPeople: pageSettings && pageSettings.preselectedPeople,
    preselectedDinners: pageSettings && pageSettings.preselectedDinners,
    productType: firstProduct.productType,
    productSelectEvent,
    position: model.position,
  };

  return (
    <div className="w-100" id={model.id}>
      <ProductGroupHero
        {...productGroupProps}
        title={model.title}
        description={model.description}
      />
    </div>
  );
};

const ContentfulProductGroupHero: React.FunctionComponent<ContentfulBaseProps> = props => (
  <StaticQuery
    query={graphql`
      query {
        allProductDb {
          edges {
            node {
              ...ProductDbFragment
            }
          }
        }
        site {
          ...SiteMetadataFragment
        }
      }
    `}
    render={(data: AllProductsQueryModel) => (
      <PureContentfulProductGroupHero {...props} {...data} />
    )}
  />
);

export default ContentfulProductGroupHero;
