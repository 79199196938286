import classnames from 'classnames';
import * as React from 'react';

interface Props {
  classNames?: string;
  style?: React.CSSProperties;
  width?: string;
}

const ResponsiveCard: React.FunctionComponent<Props> = ({
  classNames,
  style,
  children,
  width,
}) => {
  const commonStyle = {boxShadow: '0 5px 0 #d5d0c9', ...style};
  const commonClass = classnames(
    classNames,
    'card mx-md-2 mb-4 rounded-0 border-0'
  );

  if (!children) {
    return null;
  }

  const content = <React.Fragment>{children}</React.Fragment>;

  return (
    <React.Fragment>
      <div
        className={classnames('d-md-block d-none', commonClass)}
        style={{width: width || '20em', ...commonStyle}}
      >
        {content}
      </div>
      <div
        className={classnames('d-block d-md-none w-100', commonClass)}
        style={commonStyle}
      >
        {content}
      </div>
    </React.Fragment>
  );
};

export default ResponsiveCard;
