/// creates url friendly
const friendlyUrl = (phrase: string) =>
  encodeURIComponent(
    phrase
      .replace(/[^a-zA-Z0-9\- ]/g, '')
      .trim()
      .replace(/\s/g, '-')
      .toLowerCase()
  );

export {friendlyUrl};
