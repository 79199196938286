import {
  CartItemEvent,
  CartSection,
  ItemProps,
  SelectedQuantities,
} from '@mfb/cookbook';
import {isNotNullOrUndefined} from '@mfb/lego';
import {navigate} from 'gatsby';
import * as React from 'react';
import {Spinner} from 'reactstrap';

import {RecipeSelectionMode} from '../../../gatsbyLib/data/CoreApiClient';
import {CartClient, GridRecipe, RecipeClient} from '../../util/client';
import {loadRecipeDetail, loadRecipeDetailLegacy} from '../../util/loadRecipeDetail';

interface Props {
  sku: string;
  orderLink: string;
  amountToPick: number;
  recipeSelectionMode?: RecipeSelectionMode;
}

interface State {
  recipes: Array<GridRecipe>;
  isLoading: boolean;
  deliveryDateDescription?: string;
  productName?: string;
}

export class MealSelectorWrapper extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      recipes: [],
      isLoading: true,
      deliveryDateDescription: undefined,
      productName: undefined,
    };
  }

  public async componentDidMount() {
    let response;
    try {
      response = await new RecipeClient(
        process.env.GATEWAY_URL
      ).upcomingSelectionRecipes(this.props.sku);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Failed to load recipe information. ${e.message}`);
    } finally {
      if (response && response.recipes && response.recipes.length > 0) {
        this.setState({
          recipes: response.recipes,
          isLoading: false,
          deliveryDateDescription: response.deliveryDateDescription,
          productName: response.productName,
        });
      } else {
        this.setState({recipes: [], isLoading: false}, () => {
          navigate('/error', {replace: true});
        });
      }
    }
  }

  private async handleMealsSelected(selection: SelectedQuantities) {
    this.setState({isLoading: true});

    let response;
    try {
      response = await new CartClient(process.env.GATEWAY_URL).createCart({
        lines: [
          {
            sku: this.props.sku,
            quantity: 1,
            selectedRecipes: Object.keys(selection)
              .map(id => {
                const recipe = this.state.recipes.find(
                  r => r.recipeGroupRecipeId === id
                );

                if (recipe) {
                  return {
                    recipeCategoryId: recipe.recipeCategoryId,
                    quantity: selection[id],
                    recipeNumber: recipe.recipeNumber,
                    recipeVersion: recipe.recipeVersion,
                    recipePartition: recipe.recipePartition,
                  };
                }
              })
              .filter(isNotNullOrUndefined),
          },
        ],
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Failed to create cart. ${e.message}`);
    } finally {
      if (response && response.id) {
        window.location.assign(`${this.props.orderLink}?cart=${response.id}`);
      } else {
        navigate('/error', {replace: true});
      }
    }
  }

  private handleCancel() {
    window.history.back();
  }

  public render() {
    const {
      recipes,
      isLoading,
      deliveryDateDescription,
      productName,
    } = this.state;

    if (isLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{height: '60vh'}}
        >
          <Spinner style={{width: '3rem', height: '3rem'}} color="primary" />
        </div>
      );
    }

    if (recipes.length === 0) {
      return null;
    }

    const hasSingleSelectionMode =
      this.props.recipeSelectionMode === RecipeSelectionMode.Single;

    const items = recipes.map<ItemProps>(r => ({
      id: r.recipeGroupRecipeId,
      description: r.name || '',
      tags: r.recipeTagNames,
      badges: r.recipeBadgeNames,
      maxQuantity: hasSingleSelectionMode ? 1 : undefined,
      image: {
        id: `img-${r.recipeGroupRecipeId}`,
        src: r.imageUrl || '',
        alt: r.name || '',
      },
      // This check is required beacuse at this point in time, recipe modal information does not exists for MC yet.
      // When information for the recipe modal is setup this check can be removed.
      recipeModalModel: hasSingleSelectionMode ? undefined : r,
      onOpenModal: async (evt: CartItemEvent) => {
        if (r.recipeId === undefined) {
          throw new Error(
            `onOpenModal received a recipe without a recipeId. Is this on purpose?`
          );
        } else {
          evt.setModel(
            await loadRecipeDetailLegacy(
              r.recipeNumber,
              r.recipeVersion,
              r.recipePartition,
              r.recipeId,
            )
          );
        }
      },
    }));

    let sectionSubtitle, cartSubtite, deliveryDate;
    if (deliveryDateDescription) {
      const splitDate = deliveryDateDescription.split(' ', 3);
      deliveryDate = splitDate.join(' ');

      sectionSubtitle = `For ${deliveryDate} delivery`;

      const dayOfWeek = splitDate[0].substring(0, 3);
      const dayOfMonth = splitDate[1];
      const month = splitDate[2].substring(0, 3);

      cartSubtite = `${dayOfWeek} ${dayOfMonth} ${month}`;
    }

    return (
      <CartSection
        items={items}
        title={'Select your meals'}
        subtitle={sectionSubtitle}
        cartSubtitle={cartSubtite}
        desktopSummaryMaxHeight="calc(100vh - 450px)"
        responsiveCardsPerRow={{
          small: 2,
          medium: 3,
          large: 3,
          extraLarge: 3,
        }}
        disableModal={process.env.GATSBY_BRAND !== 'RMM'}
        cartTitle={{desktop: 'Your meals', mobile: 'Select meals'}}
        selectionLimit={this.props.amountToPick}
        deliveryDate={deliveryDate}
        productName={productName}
        onSubmit={async e => this.handleMealsSelected(e)}
        onCancel={() => this.handleCancel()}
      />
    );
  }
}
