import {mapAlignment} from '@mfb/lego';
import * as React from 'react';

import VideoTwoColumnSection, {
  stringToSkew,
} from '../../components/twoColumnSection/VideoTwoColumnSection';
import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {VideoTwoColumnSectionContentModel} from './VideoTwoColumnSectionContentModel';

const ContentfulVideoTwoColumnSection: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as VideoTwoColumnSectionContentModel;

  const button = model.callToAction && mapActionButton(model.callToAction);

  const text = model.childContentfulVideoTwoColumnSectionTextTextNode
    ? model.childContentfulVideoTwoColumnSectionTextTextNode.text
    : '';

  return (
    <VideoTwoColumnSection
      id={model.id}
      backgroundColor={getColor(model.backgroundColor)}
      title={model.title}
      text={text}
      button={button}
      textAlignment={mapAlignment(model.textAlignment)}
      textColor={getColor(model.textColor)}
      skew={stringToSkew(model.skew)}
      videoOnRight={model.videoOnRight}
      videoId={model.videoId}
      videoSource={model.videoSource}
    />
  );
};

export default ContentfulVideoTwoColumnSection;
