import {Dictionary, PageSection} from '@mfb/lego';
import * as React from 'react';

import {defaultImageSet} from '../../components/mapContentfulImageSetToDictionary';
import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {getColor} from '../color/ColorContentModel';
import renderContentful from '../ComponentIndex';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import PageSectionContentModel from './PageSectionContentModel';

const ContentfulPageSection: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
  pageSettings,
  navProps,
}) => {
  const model = baseModel as PageSectionContentModel;

  const background: Dictionary<string> = model.backgroundImage
    ? {
        small:
          model.backgroundImage.small && model.backgroundImage.small.file.url,
        medium:
          model.backgroundImage.medium && model.backgroundImage.medium.file.url,
        large:
          model.backgroundImage.large && model.backgroundImage.large.file.url,
      }
    : defaultImageSet;

  const button = model.actionButton && mapActionButton(model.actionButton);

  const subtitle = model.childContentfulPageSectionSubtitleTextNode;
  const description = model.childContentfulPageSectionDescriptionTextNode;
  return (
    <PageSection
      id={model.id}
      title={model.title}
      subtitle={subtitle && subtitle.subtitle}
      backgroundStyle={{backgroundColor: getColor(model.backgroundColor)}}
      wrap={model.wrapContent}
      description={description && description.description}
      backgroundImage={background}
      button={button}
      fullWidth={model.fullWidth}
      variant={model.variant}
      leftImageSrc={model.leftImage?.large?.file.url}
      rightImageSrc={model.rightImage?.large?.file.url}
    >
      {renderContentful(model.content, pageSettings, navProps)}
    </PageSection>
  );
};

export default ContentfulPageSection;
