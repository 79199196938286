import { HeroCardProps, HeroCardType} from '@mfb/lego';
import HeroCardContentModel from './HeroCardContentModel';
import AssetContentModel, { getImageModel } from '../asset/AssetContentModel';
export const mapHeroCardContentModelToProp = (model : HeroCardContentModel, typeOverride?: HeroCardType) =>{
return {
    title: model.title,
    image: getImageModel(model.image),
    description: model.description,
    type: typeOverride ?? model.cardType
} as HeroCardProps
}