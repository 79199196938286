import {SmartLink} from '@mfb/lego';
import * as React from 'react';

interface Props {
  link?: string;
  classNames?: string;
  style?: React.CSSProperties;
}

const ClickableDiv: React.FunctionComponent<Props> = ({
  link,
  classNames,
  style,
  children,
}) => (
  <SmartLink
    className={classNames}
    href={link}
    style={{
      ...style,
      textDecoration: 'inherit',
      color: 'inherit',
      cursor: link ? 'pointer' : 'default',
    }}
  >
    {children && <React.Fragment>{children}</React.Fragment>}
  </SmartLink>
);

export default ClickableDiv;
