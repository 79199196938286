import {Dictionary} from '@mfb/lego';
import * as React from 'react';

import {
  fallBackImageHost,
  fallbackImages,
  fallbackImageSizes,
} from './FallbackImageSet';
import {ImageModel} from './ImageModel';
import {BreakPoints} from './PictureBreakpoints';

interface PictureProps {
  classNames?: string;
  imageSet?: Dictionary<ImageModel>;
  image?: string;
  alt?: string;
  style?: React.CSSProperties;
}

const Picture = ({classNames, imageSet, image, alt, style}: PictureProps) => {
  if (!imageSet) {
    return (
      <picture className={classNames}>
        <source srcSet={`${image}?fm=avif`} type="image/avif" />
        <source srcSet={`${image}?fm=webp`} type="image/webp" />
        <source srcSet={`${image}?fm=jpg`} type="image/jpeg" />
        <source srcSet={`${image}?fl=progressive`} />
        <img src={image} alt={alt} className={'w-100'} style={style} />
      </picture>
    );
  }

  const large: ImageModel = imageSet.large || fallbackImages.large;
  const medium: ImageModel = imageSet.medium || fallbackImages.medium;
  const small: ImageModel = imageSet.small || fallbackImages.small;

  return (
    <picture className={classNames}>
      {large.src && (
        <React.Fragment>
          <source
            srcSet={`${large.src}?fm=avif`}
            media={`(min-width: ${BreakPoints.Large})`}
            type="image/avif"
          />
          <source
            srcSet={`${large.src}?fm=webp`}
            media={`(min-width: ${BreakPoints.Large})`}
            type="image/webp"
          />
          <source
            srcSet={`${large.src}?fm=jpg`}
            media={`(min-width: ${BreakPoints.Large})`}
            type="image/jpeg"
          />
          <source
            srcSet={`${large.src}?fl=progressive`}
            media={`(min-width: ${BreakPoints.Large})`}
          />
        </React.Fragment>
      )}

      {medium.src && (
        <React.Fragment>
          <source
            srcSet={`${medium.src}?fm=avif`}
            media={`(min-width: ${BreakPoints.Medium})`}
            type="image/avif"
          />
          <source
            srcSet={`${medium.src}?fm=webp`}
            media={`(min-width: ${BreakPoints.Medium})`}
            type="image/webp"
          />
          <source
            srcSet={`${medium.src}?fm=jpg`}
            media={`(min-width: ${BreakPoints.Medium})`}
            type="image/jpeg"
          />
          <source
            srcSet={`${medium.src}?fl=progressive`}
            media={`(min-width: ${BreakPoints.Medium})`}
          />
        </React.Fragment>
      )}

      {small.src ? (
        <React.Fragment>
          <source srcSet={`${small.src}?fm=avif`} type="image/avif" />
          <source srcSet={`${small.src}?fm=webp`} type="image/webp" />
          <source srcSet={`${small.src}?fm=jpg`} type="image/jpeg" />
          <img src={small.src} alt={alt} className="w-100" style={style} />
        </React.Fragment>
      ) : (
        <img
          src={`${fallBackImageHost}/${fallbackImageSizes.small}`}
          alt="Image not available"
          className="w-100"
          style={style}
        />
      )}
    </picture>
  );
};

export default Picture;
