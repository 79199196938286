import classnames from 'classnames';
import * as React from 'react';

import TermSection from './TermSection';
import {TermsSectionModel} from './TermSectionModel';

interface Props {
  id: string;
  title: string;
  sections: Array<TermsSectionModel>;
}

export class Terms extends React.Component<Props> {
  public sectionNumber: number;

  public constructor(props: Props) {
    super(props);
    this.sectionNumber = 0;
  }

  public render() {
    const {sections} = this.props;
    const terms = sections.filter(
      s => s.subsections && s.subsections.length > 0
    );

    const handleSectionNumberAssignment = () => {
      this.sectionNumber += 1;

      return this.sectionNumber;
    };

    return (
      <article className={classnames('container pt-4 px-5')}>
        {terms.map((section, index) => (
          <TermSection
            key={`${section.id}-${index}`}
            id={section.id}
            title={section.title}
            subsections={section.subsections}
            onSectionNumberAssignment={handleSectionNumberAssignment}
          />
        ))}
      </article>
    );
  }
}
