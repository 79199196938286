import {isNotNullOrUndefined} from '@mfb/lego';

import {CartProducts} from '../../components/cart/ProductCartWrapper';
import {ProductDbModel} from '../../customGraphQlModels/ProductDbModel';
import {getImageModel} from '../asset/AssetContentModel';
import {ProductContentModel} from '../product/ProductContentModel';

export const mergeToCartProducts = (
  defaultSku: string,
  allDbProducts: Array<ProductDbModel>,
  allContentfulProducts: Array<ProductContentModel>
): Array<CartProducts> => {
  const filteredProducts = allContentfulProducts
    .map(p => {
      const dbProduct =
        allDbProducts && allDbProducts.find(pdb => pdb.sku === p.sku);
      if (!dbProduct) {
        return undefined;
      }

      return {cp: p, dbp: dbProduct};
    })
    .filter(isNotNullOrUndefined);

  const configuredItems: Array<CartProducts> = [];
  if (defaultSku) {
    const defaultProduct =
      allDbProducts && allDbProducts.find(pdb => pdb.sku === defaultSku);

    const contentfulProduct = allContentfulProducts.find(
      p => p.sku === defaultSku
    );

    if (defaultProduct) {
      configuredItems.push({
        id: defaultSku,
        sku: defaultSku,
        title: defaultProduct.name,
        image: contentfulProduct && getImageModel(contentfulProduct.image),
        price: defaultProduct.price,
        locked: true,
      });
    }
  }

  filteredProducts.forEach(p => {
    const {cp, dbp} = p;
    configuredItems.push({
      id: dbp.sku,
      sku: dbp.sku,
      title: dbp.name,
      description: cp.description,
      tags: (cp.tabText && [cp.tabText]) || undefined,
      image: getImageModel(cp.image),
      price: dbp.price,
    });
  });

  return configuredItems;
};

export default {mergeToCartProducts};
