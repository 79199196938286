import {
  BlueCrossOutText,
  DiscountConsumer,
  ProductGroupProps,
  ProductModel,
  ProductOrderButton,
  ProductType,
} from '@mfb/cookbook';
import {Brand, CardTag} from '@mfb/lego';
import classnames from 'classnames';
import {flatten} from 'lodash';
import * as React from 'react';

import {mapSelectEventToActionButtonProps} from './mapSelectEventToActionButtonProps';
import {RecipeDisplay} from './RecipeDisplay';
import { RecipeCollectionModel } from '../../customGraphQlModels/ProductRecipeCollectionModel';

export interface ProductSelection {
  selectedProduct: ProductModel;
  selectorComponent: React.ReactNode;
}

export interface ProductGroupHeroProps extends ProductGroupProps {
  title: string;
  description: string;
}

export const PureProductGroupHero: React.FunctionComponent<ProductSelection &
  ProductGroupHeroProps> = props => {
  const {
    title,
    description,
    productSelectEvent,
    selectedProduct,
    selectorComponent,
    id,
    addQueryParamOnContinue,
    productType,
    tag,
    isSoldOut,
  } = props;

  const recipeCollections =
    (
      selectedProduct.recipeCollections &&
      flatten(selectedProduct.recipeCollections.map(sp => sp.collections))
    )?.map(rc => {
      rc.recipes = rc.recipes.filter(r => r.surcharge === 0);

      return rc;
    }) as Array<RecipeCollectionModel> || [];

  const type = productType || ProductType.Primary;
  const brand = process.env.GATSBY_BRAND;

  const orderButtonProps = mapSelectEventToActionButtonProps(
    selectedProduct,
    type === ProductType.Primary ? 'Order Now' : 'Add to my Deliveries',
    productSelectEvent,
    'add',
    addQueryParamOnContinue
  );

  const sectionId = `product-group-hero-${id}`;

  const deliveryPrice = selectedProduct.deliveryPrice;
  const showDetailedRecipePriceCard =
    deliveryPrice !== 0 && deliveryPrice !== null;

  const priceClassName = classnames(
    'd-flex',
    'align-items-center',
    {'justify-content-between': showDetailedRecipePriceCard},
    'mb-0'
  );

  return (
    <div data-test={sectionId} className="d-flex w-100 justify-content-center">
      <div
        className={classnames(
          'd-flex flex-wrap flex-md-nowrap flex-row w-100 bg-white',
          'mb-5 py-5 px-2 px-md-4',
          'justify-content-center'
        )}
        style={{maxWidth: '62em'}}
      >
        <div
          className={classnames('d-flex flex-column w-100', 'col-12 col-md-5')}
        >
          <CardTag {...tag} position={{top: '-48px'}} />
          <h3 className="display-4 m-0 mb-2">{title}</h3>
          <p>{description}</p>

          {selectorComponent}

          <DiscountConsumer
            product={selectedProduct}
            render={(price, serving) => {
              const hasDiscount = price.original !== price.discounted;

              const showServingPrice = type === ProductType.Primary && serving;

              const discountStyle = hasDiscount ? {color: '#0d6efd'} : {};
              const totalPrice = (
                parseFloat(price.discounted) + deliveryPrice
              ).toFixed(2);

              return (
                <>
                  <div>
                    <h4>
                      <p className={priceClassName} style={{width: '15em'}}>
                        {showDetailedRecipePriceCard && (
                          <span className="text-nowrap">Per Week</span>
                        )}
                        <span>
                          {hasDiscount && (
                            <span>
                              <BlueCrossOutText
                                className="mr-2"
                                text={`$${price.original}`}
                              />
                            </span>
                          )}
                          <span style={discountStyle}>${price.discounted}</span>
                        </span>
                        {!showDetailedRecipePriceCard && showServingPrice && (
                          <span className="text-nowrap">&nbsp;per week</span>
                        )}
                      </p>
                    </h4>
                    {showServingPrice && serving && (
                      <h4>
                        <p className={priceClassName} style={{width: '15em'}}>
                          {showDetailedRecipePriceCard && (
                            <span className="text-nowrap">Per Plate</span>
                          )}
                          <span>
                            {hasDiscount && (
                              <span>
                                <BlueCrossOutText
                                  className="mr-2"
                                  text={`$${serving.original}`}
                                />
                              </span>
                            )}
                            <span style={discountStyle}>
                              ${serving.discounted}
                            </span>
                            {!showDetailedRecipePriceCard &&
                              showServingPrice && (
                                <span className="text-nowrap">
                                  &nbsp;per plate
                                </span>
                              )}
                          </span>
                        </p>
                      </h4>
                    )}
                    {showDetailedRecipePriceCard && (
                      <div>
                        <h4>
                          <p className={priceClassName} style={{width: '15em'}}>
                            <span className="text-nowrap">Delivery</span>
                            <span>${deliveryPrice}</span>
                          </p>
                        </h4>
                        <h4>
                          <br />
                          <p className={priceClassName} style={{width: '15em'}}>
                            <span className="text-nowrap">Total Price</span>
                            <span>${totalPrice}</span>
                          </p>
                        </h4>
                      </div>
                    )}
                  </div>
                </>
              );
            }}
          />

          <div className="col mt-3 px-0">
            <ProductOrderButton
              sku={selectedProduct.sku}
              productType={selectedProduct.productType}
              className={classnames(
                'btn text-white text-nowrap mb-4 px-5',
                brand === Brand.BB ? 'rounded-pill' : 'rounded-lg'
              )}
              actionButton={orderButtonProps}
              isSoldOut={isSoldOut}
              isComingSoon={selectedProduct.isComingSoon}
            />
          </div>
        </div>
        <div className="w-100 d-flex flex-column col-12 col-md-7">
          <RecipeDisplay recipeCollections={recipeCollections} />
        </div>
      </div>
    </div>
  );
};
