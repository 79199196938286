import * as React from 'react';

export interface FaqCardProps {
  question: string;
  answer: string;
}

const FaqCard: React.FunctionComponent<FaqCardProps> = ({question, answer}) => (
  <div className="col-12 col-md-6 py-4 px-0 px-md-3">
    <h4>{question}</h4>
    <p className="m-0">{answer}</p>
  </div>
);

export default FaqCard;
