import {ProductModel, ProductType as LegoProductType} from '@mfb/cookbook';
import {Brand, BulletPointCardProps} from '@mfb/lego';
import {memoize} from 'lodash';

import {ProductType} from '../../../gatsbyLib/data/CoreApiClient';
import {Edges} from '../../customGraphQlModels/Edges';
import {
  findBySkuOrItemumber,
  ProductDbModel,
} from '../../customGraphQlModels/ProductDbModel';
import {ProductRecipeCollectionModel} from '../../customGraphQlModels/ProductRecipeCollectionModel';
import {getWeeksFromRecipeCollectionModel} from '../../util/getWeeksFromRecipeCollectionModel';
import {getWeekToRemove} from '../../util/getWeekToRemove';
import {getImageModel} from '../asset/AssetContentModel';
import {getColor} from '../color/ColorContentModel';
import {getContentfulLink} from '../link/LinkContentModel';
import {ProductContentModel} from './ProductContentModel';

const getFilteredProductRecipeCollections = memoize(
  (productRecipeCollections: Array<ProductRecipeCollectionModel>) =>
    productRecipeCollections.map(prc => {
      const weekToRemove = getWeekToRemove();
      const weeks = getWeeksFromRecipeCollectionModel(prc.collections).filter(
        w => w !== weekToRemove
      );

      const filteredRecipeCollections = prc.collections.filter(rc =>
        weeks.includes(rc.day)
      );

      return {...prc, collections: filteredRecipeCollections};
    })
);

export const getProductModel = (
  model: ProductContentModel,
  allDbProducts: Edges<ProductDbModel>,
  gatewayUrl: string
): ProductModel | undefined => {
  const {
    id,
    sku,
    dinners,
    people,
    description,
    productOverview,
    tabText,
    tabColor,
    image,
    productPageLink,
    isSkipRecipeSelection,
    variant,
    protein,
    hasPreferences,
  } = model;

  const dbProduct = findBySkuOrItemumber(allDbProducts, sku);

  if (dbProduct === undefined) {
    return undefined;
  }
  const {
    price,
    recipeCollections,
    deliveryPrice,
    quantityPerLine,
    brand,
    productType,
    availableFrequency,
    recipeSelectionMode,
    name,
    itemNumber,
    sku: productDbSku,
  } = dbProduct;

  const queryParams = new URLSearchParams();

  const productOverviewModel:
    | BulletPointCardProps
    | undefined = productOverview && {
    title: productOverview.title,
    image: getImageModel(productOverview.image),
    pointsAsMarkdown: productOverview.pointsAsMarkdown.pointsAsMarkdown,
  };

  const filteredProductRecipeCollections =
    recipeCollections?.slice(1);

  const quantityCalc = dinners;
  const peopleCalc = people;
  const isExtra =
    productType === ProductType.Extra ||
    productType === ProductType.OneOff ||
    productType === ProductType.Donation;

  if (!(dinners > 0 && people > 0) && !isExtra) {
    return undefined;
  }

  if(isSkipRecipeSelection){
    queryParams.set('ss', '1');
  }

  const productModel: ProductModel = {
    id,
    people: peopleCalc,
    dinners: quantityCalc,
    description,
    sku: productDbSku, // Product DB sku is used here because we still use Historical SKUs for buffer stock.
    itemNumber,
    price,
    recipeCollections: filteredProductRecipeCollections,
    cardTag: {
      text: tabText,
      color: getColor(tabColor),
    },
    deliveryPrice,
    productOverview: productOverviewModel,
    cartLink: getContentfulLink(productPageLink),
    orderLink: `${gatewayUrl || ''}/order/${productDbSku}?${queryParams.toString()}`,
    image: getImageModel(image),
    brand,
    productType: isExtra ? LegoProductType.Extra : LegoProductType.Primary,
    showServingPrice: !model.hideServingPrice,
    availableFrequency,
    recipeSelectionMode,
    isSoldOut: model.isSoldOut,
    name,
    variant,
    proteins: model.protein,
  };

  return productModel;
};
