import * as React from 'react';

import SimpleJumbotron from '../../components/SimpleJumbotron';
import {getImageModel} from '../asset/AssetContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {getContentfulLink} from '../link/LinkContentModel';
import SimpleJumbotronContentModel from './SimpleJumbotronContentModel';

const ContentfulSimpleJumbotron: React.FC<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const {
    id,
    image,
    link,
    scrollTarget,
  } = baseModel as SimpleJumbotronContentModel;

  const imageSet = {
    small: getImageModel(image.small),
    medium: getImageModel(image.medium),
    large: getImageModel(image.large),
  };

  return (
    <SimpleJumbotron
      key={id}
      imageSet={imageSet}
      altText={image.alternateText}
      link={getContentfulLink(link)}
      scrollTarget={scrollTarget && scrollTarget.id}
    />
  );
};

export default ContentfulSimpleJumbotron;
