import {mapAlignment, Markdown} from '@mfb/lego';
import * as React from 'react';

import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {MarkdownContentModel} from './MarkdownContentModel';

const ContentfulMarkdown: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as MarkdownContentModel;

  return (
    <Markdown
      text={model.childContentfulMarkdownTextTextNode.text}
      alignment={mapAlignment(model.alignment)}
      fontColor={getColor(model.fontColor)}
    />
  );
};

export default ContentfulMarkdown;
