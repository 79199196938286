import {graphql} from 'gatsby';
import * as React from 'react';

import renderContentful from '../contentful/ComponentIndex';
import LandingPageContentModel from '../contentful/landingPage/LandingPageContentModel';
import PageTemplate from './PageTemplate';

export const indexPageQuery = graphql`
  query($id: String!) {
    contentfulLandingPage(id: {eq: $id}) {
      ...BaseInfoFragment
      ...LandingPageFragment
    }
  }
`;

interface Props {
  data: Data;
  location: any;
}

interface Data {
  contentfulLandingPage: LandingPageContentModel;
}

const LandingPageTemplate: React.FunctionComponent<Props> = ({
  data,
  location,
}) => {
  return(
  <PageTemplate
    {...data.contentfulLandingPage}
    render={settings => (
      <>
        {
        renderContentful(
          data.contentfulLandingPage.pageSections,
          settings,
          location.state
        )}
      </>
    )}
  />
)};

export default LandingPageTemplate;
