import {
  ResponsiveBackground,
  ResponsiveSquare,
  ResponsiveTitle,
} from '@mfb/lego';
import * as React from 'react';

import ClickableDiv from '../ClickableDiv';
import {MerchandisingProps} from './MerchandisingProps';

const MerchandisingSquare: React.FunctionComponent<MerchandisingProps> = ({
  id,
  img,
  title,
  subtitle,
  backgroundColor,
  mediaLink,
}) => {
  const link = mediaLink && mediaLink.url;

  return (
    <div className="col-12 col-md-4 px-0 py-2 text-white p-md-2">
      <ClickableDiv link={link} style={{backgroundColor}}>
        <ResponsiveSquare id={id}>
          <ResponsiveBackground
            className="justify-content-end align-items-center w-100 h-100 p-2"
            id={id}
            image={img}
            backgroundStyle={{
              gradient:
                'linear-gradient(to top, rgba(33,33,33,0.6) 0%, rgba(0,0,0,0) 75%)',
            }}
          >
            <React.Fragment>
              <h1 className="h4 pb-2 p-lg-4 text-center">{subtitle}</h1>
              <ResponsiveTitle
                id={id}
                text={title}
                className="text-center display-1 pb-0 pb-md-2"
                fontSizes={{small: '4rem', large: '5.5rem'}}
              />
            </React.Fragment>
          </ResponsiveBackground>
        </ResponsiveSquare>
      </ClickableDiv>
    </div>
  );
};

export default MerchandisingSquare;
