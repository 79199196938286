import {Bold, CardTag} from '@mfb/lego';
import * as React from 'react';

import Picture from '../Picture';
import {MerchandisingProps} from './MerchandisingProps';
import PlayButton from './PlayButton';

const MerchandisingFullWidth: React.FunctionComponent<MerchandisingProps> = ({
  img,
  tag,
  title,
  subtitle,
  backgroundColor,
  mediaLink,
}) => (
  <div className="row w-100" style={{backgroundColor}}>
    <div className="col-12 col-lg-6 text-white px-0">
      <CardTag className="mx-5 p-1 rounded-bottom" {...tag} />
      <div className="m-5 my-lg-0 row pt-4">
        <div className="col-md-10 px-0">
          <h1 className="display-3 px-0 mb-4">{title}</h1>
          <p className="px-0 mb-2">{subtitle}</p>
          {mediaLink && mediaLink.url && (
            <a
              rel="noopener noreferrer"
              href={mediaLink.url}
              target="_blank"
              className="d-flex align-items-center text-uppercase text-white"
            >
              <small className="mr-1">
                <Bold>{mediaLink.label}</Bold>{' '}
              </small>
              <PlayButton />
            </a>
          )}
        </div>
      </div>
    </div>
    <div className="col-12 col-lg-6 px-0">
      <Picture alt={title} image={img} />
    </div>
  </div>
);

export default MerchandisingFullWidth;
