import {OvercomeHesitation} from '@mfb/lego';
import * as React from 'react';

interface Props {
  title: string;
  subtitleBulletPoints?: Array<string>;
  backgroundColor?: string;
}

const SimpleHeader: React.FunctionComponent<Props> = ({
  title,
  subtitleBulletPoints,
  backgroundColor,
}) => (
  <div className="container-fluid pt-5 pb-2" style={{backgroundColor}}>
    <h1 className="display-1 w-100 text-center">{title}</h1>
    {subtitleBulletPoints && (
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        <OvercomeHesitation options={subtitleBulletPoints} isHorizontal />
      </div>
    )}
  </div>
);

export default SimpleHeader;
