import {
  Alignment,
  getFlexAlignmentClasses,
  PageSection,
  ResponsiveVideoEmbed,
} from '@mfb/lego';
import classnames from 'classnames';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import {
  buildVideoUrl,
  stringToVideoSource,
} from '../../contentful/videoEmbed/VideoSource';
import ActionButton, {ActionButtonProps} from '../ActionButton';
import {getRenderers} from './TwoColumnSectionUtils';

export interface VideoTwoColumnSectionProps {
  id: string;
  title?: string;
  backgroundColor?: string;
  text: string;
  textColor?: string;
  textAlignment?: Alignment;
  button?: ActionButtonProps;
  videoSource: string;
  videoId: string;
  videoOnRight?: boolean;
  skew?: Content;
}

export enum Content {
  Video = 'Leading',
  Text = 'Trailing',
  Equal = 'None',
}

export const stringToSkew = (s?: string) => Content[s as keyof typeof Content];

const baseColWidth = 'col-12';
const getColumnWidthClass = (skew: Content, side: Content) => {
  if (skew === Content.Equal) {
    return `${baseColWidth} col-lg-6`;
  } else if (skew === side) {
    return `${baseColWidth} col-lg-8`;
  }

  // skew !== Content.None && skew !== side
  return `${baseColWidth} col-lg-4`;
};

const VideoTwoColumnSection: React.FunctionComponent<VideoTwoColumnSectionProps> = ({
  id,
  title,
  backgroundColor,
  text,
  textColor,
  textAlignment = Alignment.Left,
  button,
  videoSource,
  videoId,
  videoOnRight,
  skew = Content.Equal,
}) => {
  const renderers = getRenderers();

  // Spacing class calculation copied from TwoColumnSection

  // If this section has a title, then there should be enough spacing between the title and content, and between
  // adjacent page sections. But also, if no title exists, then the image should span the entire page section height
  // while padding added to only the text content. Setting pageSectionYSpace to undefined means default page section
  // vertical spacing is used.

  let pageSectionYSpace: string | undefined = 'py-0';
  let contentYSpace = 'py-4 py-lg-5';

  if (title) {
    pageSectionYSpace = undefined;
    contentYSpace = 'py-4 py-lg-0';
  }

  // If this section has a background color, then on mobile, there should be enough spacing between the content
  // div edge and the text. But if there is no background color, the text looks indented and out of line, so there
  // should not be any horizontal spacing. px-lg-4 is always required, to apply spacing between the image and text.

  let contentXSpace = 'px-0 px-lg-4';

  if (backgroundColor) {
    contentXSpace = 'px-3 px-lg-4';
  }

  return (
    <PageSection
      id={id}
      title={title}
      verticalSpaceClassName={pageSectionYSpace}
      childrenContainerClassName={videoOnRight ? 'flex-lg-row-reverse' : ''}
    >
      <div
        className={classnames(
          'd-flex flex-column',
          contentXSpace,
          contentYSpace,
          getColumnWidthClass(skew, Content.Video),
          getFlexAlignmentClasses(Alignment.Center, 'col')
        )}
        style={{color: textColor, backgroundColor}}
      >
        <ResponsiveVideoEmbed
          src={buildVideoUrl(stringToVideoSource(videoSource), videoId)}
        />
      </div>
      <div
        className={classnames(
          'd-flex flex-column',
          contentXSpace,
          contentYSpace,
          getColumnWidthClass(skew, Content.Text),
          getFlexAlignmentClasses(textAlignment, 'col')
        )}
        style={{color: textColor, backgroundColor}}
      >
        <ReactMarkdown
          className="w-100"
          source={text}
          allowedTypes={[
            'root',
            'text',
            'break',
            'paragraph',
            'emphasis',
            'strong',
            'thematicBreak',
            'blockquote',
            'delete',
            'link',
            'image',
            'linkReference',
            'list',
            'listItem',
            'heading',
          ]}
          unwrapDisallowed={true}
          renderers={renderers}
        />
        {button && <ActionButton {...button} />}
      </div>
    </PageSection>
  );
};

export default VideoTwoColumnSection;
