import * as React from 'react';

import FaqCard from '../../components/FaqCard';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import FaqCardContentModel from './FaqCardContentModel';

const ContentfulFaqCard: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as FaqCardContentModel;

  return <FaqCard question={model.question} answer={model.answer.answer} />;
};

export default ContentfulFaqCard;
