import {navigate} from 'gatsby';
import * as React from 'react';

import {MealSelectorWrapper} from '../../components/cart/MealSelectorWrapper';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {GatsbyNavProps} from '../GatsbyNavProps';
import {getContentfulLink} from '../link/LinkContentModel';
import {MealSelectorContentModel} from './MealSelectorContentModel';

export default class ContentfulMealSelector extends React.PureComponent<
  ContentfulBaseProps
> {
  private validNavProps(navProps?: GatsbyNavProps): boolean {
    return (
      (navProps &&
        navProps.amountToPick &&
        navProps.orderLink &&
        navProps.sku &&
        true) ||
      false
    );
  }

  public componentDidMount() {
    if (!this.validNavProps(this.props.navProps)) {
      const model = this.props.baseModel as MealSelectorContentModel;
      const relativePath = getContentfulLink(model.returnUrl) || '/';

      navigate(relativePath, {replace: true});
    }
  }

  public render() {
    const {navProps} = this.props;

    if (!navProps) {
      return null;
    }

    const {sku, amountToPick, orderLink, recipeSelectionMode} = navProps;

    return (
      (sku && amountToPick && orderLink && (
        <MealSelectorWrapper
          sku={sku}
          amountToPick={amountToPick}
          orderLink={orderLink}
          recipeSelectionMode={recipeSelectionMode}
        />
      )) ||
      null
    );
  }
}
