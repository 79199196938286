import {ResponsiveImage} from '@mfb/lego';
import * as React from 'react';

import ClickableDiv from '../../components/ClickableDiv';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {getContentfulLink} from '../link/LinkContentModel';
import {mapResponsiveImageSetContentModelToResponsiveImageProps} from '../responsiveImageSet/ResponsiveImageSetContentModel';
import MerchSlotContentModel from './MerchSlotContentModel';

const ContentfulMerchSlot: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as MerchSlotContentModel;
  const {name, link, responsiveImageSet} = model;

  const imageSet =
    responsiveImageSet &&
    mapResponsiveImageSetContentModelToResponsiveImageProps(responsiveImageSet);

  return imageSet ? (
    <div
      data-category="merchandising"
      data-action="click-single-merch-slot"
      data-label={`${name} - merch-slot`}
      className="w-100 my-2 mx-lg-2"
    >
      <ClickableDiv link={getContentfulLink(link)}>
        <ResponsiveImage {...imageSet} />
      </ClickableDiv>
    </div>
  ) : null;
};

export default ContentfulMerchSlot;
