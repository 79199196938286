import {imageFillStyle, Placeholder, ResponsiveSquare} from '@mfb/lego';
import * as React from 'react';
import * as Img from 'react-image';

import ActionButton, {ActionButtonProps} from '../ActionButton';
import {ImageModel} from '../ImageModel';
import ResponsiveCard from '../ResponsiveCard';

export interface CallToActionCardProps {
  id: string;
  title: string;
  image: ImageModel;
  description: string;
  button?: ActionButtonProps;
}

export const CallToActionCard: React.FunctionComponent<CallToActionCardProps> = ({
  id,
  title,
  description,
  button,
  image,
}) => (
  <ResponsiveCard>
    <div className="d-md-flex h-100 flex-column">
      {image && (
        <ResponsiveSquare id={id} paddingSizes={{small: '60%'}}>
          <Img
            src={image.src}
            alt={image.alt}
            loader={
              <Placeholder outerWidth={360} id={`placeholder-image-${id}`} />
            }
            className="h-100 w-100"
            style={imageFillStyle}
          />
        </ResponsiveSquare>
      )}
      <div className="d-flex flex-column justify-content-md-between p-3 pb-2 w-100 h-100">
        <div className="d-flex flex-column align-items-center justify-content-start text-center w-100">
          <h4>{title}</h4>
          <p className="w-75 mb-0 mb-lg-3">{description}</p>
        </div>
        <div className="text-center w-100 mt-2">
          {button && <ActionButton {...button} className={'btn-block'} />}
        </div>
      </div>
    </div>
  </ResponsiveCard>
);
