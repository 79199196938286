export enum VideoSource {
  YouTube = 'YouTube',
  Vimeo = 'Vimeo',
}

export const stringToVideoSource = (s?: string) =>
  VideoSource[s as keyof typeof VideoSource];

export const buildVideoUrl = (source: VideoSource, id: string): string => {
  switch (source) {
    case VideoSource.YouTube:
      return `https://youtube.com/embed/${id}?rel=0`;
    case VideoSource.Vimeo:
      return `https://player.vimeo.com/video/${id}?title=0&portrait=0&byline=0`;
  }
};
