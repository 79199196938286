import * as React from 'react';

import {friendlyUrl} from '../../util/friendlyUrl';
import {BaseTermSectionProps} from './BaseTermSectionProps';
import {TermsSectionModel} from './TermSectionModel';
import TermSubsection from './TermSubsection';

const TermSection: React.FunctionComponent<TermsSectionModel &
  BaseTermSectionProps> = ({
  id,
  subsections,
  title,
  onSectionNumberAssignment,
}) => {
  const sectionName = friendlyUrl(title);

  return (
    <section key={id}>
      <h1 className="h1 display-4" id={sectionName}>
        {title}
      </h1>
      {subsections &&
        subsections
          .filter(s => s.text && s.text !== '')
          .map((subsection, index) => (
            <TermSubsection
              key={`${subsection.id}-${index}`}
              id={subsection.id}
              title={subsection.title}
              onSectionNumberAssignment={onSectionNumberAssignment}
              text={subsection.text}
            />
          ))}
    </section>
  );
};

export default TermSection;
