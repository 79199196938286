import {PageSection} from '@mfb/lego';
import * as React from 'react';

import ClickableDiv from '../ClickableDiv';
import Picture from '../Picture';
import InstagramPicture from './InstagramPicture';

interface Props {
  id: string;
  title: string;
  profileHandle: string;
  shortCodes?: Array<string>;
  iconUrl?: string;
}

const InstagramSection: React.FunctionComponent<Props> = ({
  id,
  title,
  shortCodes,
  iconUrl,
  profileHandle,
}) => (
  <PageSection id={id} wrap={true}>
    <ClickableDiv
      classNames="d-flex flex-wrap justify-content-center align-items-center"
      link={`https://instagram.com/${profileHandle}/`}
    >
      {iconUrl && (
        <Picture
          classNames="pr-3"
          image={iconUrl}
          style={{maxHeight: '3em', maxWidth: '3em'}}
        />
      )}
      {title && <h1 className="display-3 mx-auto text-center ">{title}</h1>}
    </ClickableDiv>
    {shortCodes && shortCodes.length > 1 && (
      <div className="d-flex justify-content-center flex-wrap pt-4 w-100">
        {shortCodes.map((c, i) => (
          <div
            className="col-6 p-1 col-md-3 p-md-0"
            key={`instagram-${i}-${c}`}
          >
            <InstagramPicture shortCode={c} />
          </div>
        ))}
      </div>
    )}
  </PageSection>
);

export default InstagramSection;
