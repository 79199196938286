import { el } from "date-fns/locale";
import { Brand } from "../../util/client";
import {QueryParams} from "./ContentfulProductSelectorTabset";


export const getSelectedBrand = () => {
    if (typeof window !== `undefined`) {
        try {
            const params = new URLSearchParams(window.location.search);
            const selectedBrand = params.get(QueryParams.selectedBrand);

            return (selectedBrand);
        } catch (e) {
            console.error(e);
        }
    }
}

export const getSelectedSku = () => {
    if (typeof window !== `undefined`) {
        try {
            const params = new URLSearchParams(window.location.search);
            const selectedSku = params.get(QueryParams.sku);

            return (selectedSku);
        } catch (e) {
            console.error(e);
        }
    }
}


