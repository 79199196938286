import {EventProps, isLinkProps, LinkProps, ProductModel} from '@mfb/cookbook';
import {ActionButtonProps, DataAction} from '@mfb/lego';

export const mapSelectEventToActionButtonProps = (
  selectedProduct: ProductModel,
  label: string,
  selectEvent: LinkProps | EventProps,
  dataAction: DataAction,
  addQueryParamOnContinue?: string
): ActionButtonProps => {
  let url;
  let onClickAsync;

  if (isLinkProps(selectEvent)) {
    url = selectEvent.generateLink(
      selectedProduct.sku,
      addQueryParamOnContinue
    );
  } else {
    onClickAsync = async () =>
      await selectEvent.onClickAsync(selectedProduct.sku);
  }

  const {sku, orderLink, dinners, recipeSelectionMode} = selectedProduct;

  return {
    label,
    url,
    onClickAsync,
    dataCategory: 'product',
    dataLabel: sku,
    dataAction,
    state: {
      sku,
      orderLink,
      amountToPick: dinners,
      recipeSelectionMode,
    },
  };
};
