import {SmartLink} from '@mfb/lego';
import classnames from 'classnames';
import * as React from 'react';

import {mfbScrollSmooth} from './MFBScroll';

export interface ActionButtonProps {
  label: string;
  url?: string;
  scrollTarget?: string;
  buttonType?: string;
  className?: string;
}

export default class ActionButton extends React.PureComponent<
  ActionButtonProps,
  any
> {
  public handleClick() {
    const {scrollTarget} = this.props;

    if (scrollTarget) {
      mfbScrollSmooth(scrollTarget);
    }
  }

  public render() {
    const {url, label, scrollTarget, buttonType, className} = this.props;

    let buttonClass = '';

    switch (buttonType) {
      case 'Primary':
        buttonClass = 'btn-primary';
        break;
      case 'Secondary':
        buttonClass = 'btn-secondary';
        break;
      case 'Made':
        buttonClass = 'btn-made';
        break;
      default:
        buttonClass = 'btn-primary';
        break;
    }

    return (
      <React.Fragment>
        {url && (
          <SmartLink
            className={classnames('btn', buttonClass, className)}
            href={url}
          >
            {label}
          </SmartLink>
        )}
        {scrollTarget && (
          <button
            name="button"
            type="button"
            value={label}
            onClick={() => this.handleClick()}
            className={classnames('btn', buttonClass, className)}
          >
            {label}
          </button>
        )}
      </React.Fragment>
    );
  }
}
