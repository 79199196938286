import * as React from 'react';

interface PictureProps {
  shortCode: string;
  style?: React.CSSProperties;
}

const InstagramPicture = ({shortCode, style}: PictureProps) => {
  const medium = `https://instagram.com/p/${shortCode}/media/?size=m`;

  return (
    <a
      href={`https://instagram.com/p/${shortCode}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={medium} alt={shortCode} width="100%" style={style} />
    </a>
  );
};

export default InstagramPicture;
