import {CardTagProps} from '@mfb/lego';
import * as React from 'react';

import MerchandisingFullWidth from '../../components/merchandising/MerchandisingFullWidth';
import {MerchandisingProps} from '../../components/merchandising/MerchandisingProps';
import MerchandisingSquare from '../../components/merchandising/MerchandisingSquare';
import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import MerchandisingContentModel from './MerchandisingContentModel';

const cardTypes = {
  FullWidthVideo: 'Full-Width Video',
  Square: 'Square',
};

const ContentfulMerchandising: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as MerchandisingContentModel;

  const mediaLink = model.mediaLink && mapActionButton(model.mediaLink);

  const tag: CardTagProps = {
    text: model.tabText,
    color: getColor(model.tabColor),
  };

  const merchProps: MerchandisingProps = {
    id: model.id,
    title: model.title,
    tag,
    subtitle: model.subtitle,
    img: model.image.file.url,
    backgroundColor: model.backgroundColor.colorHex,
    mediaLink: model.mediaLink && mediaLink,
  };

  switch (model.cardType) {
    case cardTypes.FullWidthVideo: {
      return <MerchandisingFullWidth {...merchProps} />;
    }
    case cardTypes.Square: {
      return <MerchandisingSquare {...merchProps} />;
    }
    default: {
      return null;
    }
  }
};

export default ContentfulMerchandising;
