import {CartSection, ItemProps, SelectedQuantities} from '@mfb/cookbook';
import {navigate} from 'gatsby';
import * as React from 'react';
import {Spinner} from 'reactstrap';

import {
  CartClient,
  CartLine,
} from '../../util/client';
import {ApimClientConfiguration, ProductOptionStockAvailability, WebsiteApiClient} from '../../../gatsbyLib/data/WebsiteApiClient';
import { fetchFromWebsiteApi} from '../../../gatsbyLib/data/fetchData';

export interface CartProducts extends ItemProps {
  sku: string;
}

export interface ProductCartWrapperProps {
  title?: string;
  orderLink: string;
  items: Array<CartProducts>;
  gatewayUrl: string;
}

interface State {
  items: Array<CartProducts>;
  isLoading: boolean;
  shouldContinueToOrderForm: boolean;
}

const cartShouldContinueToOrderForm = (
  productStock: Array<ProductOptionStockAvailability>
) => productStock.length === 0 || !productStock.some(a => a.hasStockAvailable);

const mergeStockInformation = (
  products: Array<CartProducts>,
  productStock: Array<ProductOptionStockAvailability>
) => {
  const productsWithStockInfo: Array<CartProducts> = [];
  products.forEach(product => {
    const stock = productStock.find(s => s.addOnSku === product.sku);

    if (product.locked || stock) {
      product.outOfStock = stock && !stock.hasStockAvailable;
      productsWithStockInfo.push(product);
    }
  });

  return productsWithStockInfo;
};

export class ProductCartWrapper extends React.Component<
  ProductCartWrapperProps,
  State
> {
  private mounted: boolean;
  public constructor(props: ProductCartWrapperProps) {
    super(props);

    this.mounted = false;
    this.state = {
      items: [],
      isLoading: true,
      shouldContinueToOrderForm: false,
    };
  }

  public async componentDidMount() {
    this.mounted = true;
    const primaryProduct = this.props.items.find(i => i.locked as boolean);

    if (!primaryProduct) {
      this.setState({isLoading: false}, () => {
        console.error('No primaryProduct sku found', window.history.state);
        // Navigate to return url
        // navigate('/', {replace: true});
      });

      return;
    }

    let response: ProductOptionStockAvailability[] = []; 
    try {
        const config = new ApimClientConfiguration();

        config.setApiKey(process.env.GATSBY_WEBSITE_API_KEY as string, '', '')

        const client = new WebsiteApiClient(
          config,
          process.env.GATSBY_WEBSITE_API_URL,
          fetchFromWebsiteApi
        );

        response = await client.productsByskuAddonsActive(primaryProduct.sku);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Failed to load primary product addons. ${e.message}`);
    } finally {
      if (this.mounted) {
        const addOnSkus = response
          ? response.filter(c => c !== null || c !== undefined)
          : [];
        const shouldContinueToOrderForm = cartShouldContinueToOrderForm(
          addOnSkus
        );

        this.setState(
          {
            items: mergeStockInformation(this.props.items, addOnSkus),
            isLoading: false,
            shouldContinueToOrderForm,
          },
          () => {
            if (shouldContinueToOrderForm) {
              window.location.assign(this.props.orderLink);
            }
          }
        );
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  // Ensure the Cart includes the primary/locked product in the selection
  public async handleMealsSelected(selection: SelectedQuantities) {
    this.setState({isLoading: true});

    const lines: Array<CartLine> = [];

    for (const key in selection) {
      // eslint-disable-next-line no-prototype-builtins
      if (selection.hasOwnProperty(key)) {
        lines.push({sku: key, quantity: selection[key]});
      }
    }

    let response;
    try {
      response = await new CartClient(this.props.gatewayUrl).createCart({
        lines,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Failed to create cart. ${e.message}`);
    } finally {
      if (response && response.id) {
        window.location.assign(`${this.props.orderLink}?cart=${response.id}`);
      } else {
        navigate('/error', {replace: true});
      }
    }
  }

  public handleCancel() {
    window.history.back();
  }

  public render() {
    const {items, isLoading, shouldContinueToOrderForm} = this.state;

    if (isLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{height: '60vh'}}
        >
          <Spinner style={{width: '3rem', height: '3rem'}} color="primary" />
        </div>
      );
    }

    if (shouldContinueToOrderForm) {
      return null;
    }

    return (
      <CartSection
        title={this.props.title}
        items={items}
        maxWidth="1600px"
        desktopSummaryMaxHeight="calc(100vh - 270px)"
        disableModal={true}
        responsiveCardsPerRow={{
          small: 1,
          medium: 2,
          large: 2,
          extraLarge: 3,
        }}
        cartTitle={{desktop: 'Your items', mobile: 'Select items'}}
        onSubmit={async e => this.handleMealsSelected(e)}
        onCancel={() => this.handleCancel()}
        // work around for Safari vh calculations to take into account browser address/navigation bar
        height={'calc(var(--vh, 1vh) * 100 - 76px)'}
      />
    );
  }
}
