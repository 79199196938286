export default [
  {
    name: 'ContentfulJumbotron',
    path: './jumbotron/ContentfulJumbotron',
  },
  {
    name: 'ContentfulSimpleJumbotron',
    path: './jumbotron/ContentfulSimpleJumbotron',
  },
  {
    name: 'ContentfulPageSection',
    path: './pageSection/ContentfulPageSection',
  },
  {
    name: 'ContentfulFaqCard',
    path: './faqCard/ContentfulFaqCard',
  },
  {
    name: 'ContentfulHeroCard',
    path: './heroCard/ContentfulHeroCard',
  },
  {
    name: 'ContentfulRenderRawHtml',
    path: './calorieCalculatorRender/ContentfulRawHtmlRender',
  },
  {
    name: 'ContentfulMerchandising',
    path: './merchandising/ContentfulMerchandising',
  },
  {
    name: 'ContentfulLineBreak',
    path: './lineBreak/ContentfulLineBreak',
  },
  {
    name: 'ContentfulResponsiveImageSet',
    path: './responsiveImageSet/ContentfulResponsiveImageSet',
  },
  {
    name: 'ContentfulInstagramSection',
    path: './instagramSection/ContentfulInstagramSection',
  },
  {
    name: 'ContentfulProductGroup',
    path: './productGroup/ContentfulProductGroup',
  },
  {
    name: 'ContentfulProductGroupHero',
    path: './productGroupHero/ContentfulProductGroupHero',
  },
  {
    name: 'ContentfulSimpleHeader',
    path: './simpleHeader/ContentfulSimpleHeader',
  },
  {
    name: 'ContentfulTermsAndConditionsSection',
    path: './termsAndConditionsSection/ContentfulTermsAndConditionsSection',
  },
  {
    name: 'ContentfulBulletPointCard',
    path: './bulletPointCard/ContentfulBulletPointCard',
  },
  {
    name: 'ContentfulProduct',
    path: './product/ContentfulProduct',
  },
  {
    name: 'ContentfulTwoColumnSection',
    path: './twoColumnSection/ContentfulTwoColumnSection',
  },
  {
    name: 'ContentfulVideoTwoColumnSection',
    path: './twoColumnSection/ContentfulVideoTwoColumnSection',
  },
  {
    name: 'ContentfulRecipeCarousel',
    path: './recipeCarousel/ContentfulRecipeCarousel',
  },
  {
    name: 'ContentfulMealSelector',
    path: './mealSelector/ContentfulMealSelector',
  },
  {
    name: 'ContentfulCallToActionCard',
    path: './callToActionCard/ContentfulCallToActionCard',
  },
  {
    name: 'ContentfulProductCartSection',
    path: './productCartSection/ContentfulProductCartSection',
  },
  {
    name: 'ContentfulMarkdown',
    path: './markdown/ContentfulMarkdown',
  },
  {
    name: 'ContentfulVideoEmbed',
    path: './videoEmbed/ContentfulVideoEmbed',
  },
  {
    name: 'ContentfulMerchSlot',
    path: './merchSlot/ContentfulMerchSlot',
  },
  {
    name: 'ContentfulDoubleMerchSlot',
    path: './doubleMerchSlot/ContentfulDoubleMerchSlot',
  },
  {
    name: 'ContentfulBagSelector',
    path: './bagSelector/ContentfulBagSelector',
  },
  {
    name: 'ContentfulProductSelectorTabset',
    path: './productSelectorTabSet/ContentfulProductSelectorTabset',
  },
  {
    name: 'ContentfulSubscriptionPlan',
    path: './subscriptionPlan/ContentfulSubscriptionPlan',
  },
];
