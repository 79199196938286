import fetch, {RequestInit} from 'node-fetch';
import { IAuthClientConfig } from './WebsiteAgentClient';

export interface FetchProps {
  url: string;
  query?: RequestInit;
}

export const fetchFromCoreApi = {
  fetch: async (url: any, init: any): Promise<any> => {
    const initObject = {
      ...init,
      headers: {'X-CoreApiKey': process.env.API_KEY || ''},
    };

    return (await fetch(url as string, initObject || undefined)) as any;
  },
};

export const fetchFromWebsiteAgent = {
  fetch: async (url: any, init: any): Promise<any> => {
    const initObject = {
      ...init,
    };

    url +=
      '?' +
      new URLSearchParams({
        code: process.env.GATSBY_WEBSITE_AGENT_FUNCTION_KEY as string,
      }).toString();

    return (await fetch(url as string, initObject || undefined)) as any;
  },
};

export const fetchFromWebsiteApi = {
  fetch: async (url: any, init: any): Promise<any> => {
    const initObject = {
      ...init,
    };
    return (await fetch(url as string, initObject || undefined)) as any;
  },
};

export const tryFetchFromApi = async <T extends {}>(
  fetchProps: FetchProps
): Promise<T | undefined> => {
  const result = await fetch(fetchProps.url, fetchProps.query);

  if (!result.ok) {
    // eslint-disable-next-line no-console
    console.log(await result.text());

    return undefined;
  }

  return (await result.json()) as T;
};

export function getEmptyTokenConfig(): IAuthClientConfig {
  const config: IAuthClientConfig = {
    getAuthorization: () => '',
  };
  return config;
}
