import * as React from 'react';

import SimpleHeader from '../../components/SimpleHeader';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import SimpleHeaderContentModel from './SimpleHeaderContentModel';

const ContentfulSimpleHeader: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as SimpleHeaderContentModel;

  return (
    <SimpleHeader
      title={model.title}
      subtitleBulletPoints={model.subtitleBulletPoints}
      backgroundColor={getColor(model.backgroundColor)}
    />
  );
};

export default ContentfulSimpleHeader;
