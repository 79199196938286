import {
  BagSelector,
  BagSelectorLayoutVariant,
  BagSelectorProductGroup,
  BagSelectorProductResult,
  BagSelectorProps,
  BagSelectorStateService,
  calculateDiscount,
  ProductModel,
  ProductPriceDisplayContext,
  ProductsMappingService,
  ProductType,
  RecipeCarouselContext,
  useDiscountContext,
} from '@mfb/cookbook';
import {
  Brand,
  BrandSelector,
  CampaignToggleProps,
  GroupedPreference,
  HeroCardType,
  LegoBaseTheme,
  RecipeCarouselItemVariants,
  StateType,
} from '@mfb/lego';
import {graphql, useStaticQuery} from 'gatsby';
import {difference, flatten, identity, maxBy, uniq} from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {getProductModel} from '../product/getProductModel';
import {AllProductsQueryModel} from '../productCard/AllProductsQueryModel';
import {getProductSelectEventPath} from '../productGroup/getProductSelectEventPath';
import classNames from 'classnames';
import {Edges} from '../../customGraphQlModels/Edges';
import {PreferenceModel} from '../../customGraphQlModels/PreferenceModel';
import {Ratings} from '../../components/review/ratingsReviewsIo';
import {useAnalyticsTracking, useUtilFunctions} from '../../hooks';
import {getSelectedBrand, getSelectedSku} from './PreSelectedBag';
import {ProductSelectorTabsetContentModel} from './ProductSelectorTabSetContentModel';
import {mapHeroCardContentModelToProp} from '../heroCard/ModelToPropMapper';
import {PromotedCampaignForBrandsModel} from '../../customGraphQlModels/PromotedCampaignForBrandsModel';
import {PreferenceProductModel} from '../../customGraphQlModels/preferenceProductModel';
import { fetchNonPromotedCampaigns } from '../../../gatsbyLib/data/fetchNonPromotedCampaign';
import { NonPromotedCampaignsResponse } from '../../../gatsbyLib/data/WebsiteAgentClient';
import { GroupedPreferenceModel } from '../../customGraphQlModels/GroupedPreferenceModel';

const BRAND_GROUPS = {
  [Brand.MFB]: 'CHOICE',
  [Brand.RMM]: 'MADE',
  [Brand.FS]: 'FSCHOICE',
  [Brand.BB]: 'BBCHOICE',
};
export const enum QueryParams {
  selectedBrand = 'selectedBrand',
  sku = 'sku',
}

interface GroupedProducts {
  [key: string]: string[];
}

const StyledBagSelector = styled(BagSelector)`
  && {
    /** Add margin to the Button Choices! */
    [role='toolbar'] {
      &.btn-toolbar {
        @media ${LegoBaseTheme.layout.deviceMediaQueries.min.lg} {
          margin-right: 3em;
        }
      }
    }
  }
`;

const RatingsWrapper = styled.div`
  margin-top: 48px;
  @media ${c => c.theme.layout.deviceMediaQueries.max.md} {
    margin-top: 32px;
  }
`;
const ContentfulProductSelectorTabset: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
  //pageSettings = {},
}) => {
  const {sortByArray} = useUtilFunctions();
  const model = baseModel as ProductSelectorTabsetContentModel;
  const preSelectedSku = getSelectedSku();
  const preSelectedBrand = getSelectedBrand();
  const firstSelector = model.productSelector[0];
  

  const optionalProps: Record<string, number | string | boolean> = {};

  const [carouselContext, setCarouselContext] = React.useState<
    RecipeCarouselContext
  >({
    recipeCollections: [],
    showDateSelector: false,
    disableDetails: false,
    carouselItemVariant: RecipeCarouselItemVariants.RecipeCard,
    showEmptySrcImages: false,
  });
  const {discount, isLoading} = useDiscountContext();
  const {trackDataLayerEvent} = useAnalyticsTracking();
  const [selectedProduct, setSelectedProduct] = React.useState<
    BagSelectorProductResult
  >();

  const {
    allProductDb,
    site,
    allPromotedCampaignForBrands: allPromotedCampaignsForbrandsDb,
    allPreferenceProduct: allPreferenceProductsDb,
    allPreferences: allPreferencesDb,
    allGroupedPreferences: allGroupedPreferencesDb,
  } = useStaticQuery<
    AllProductsQueryModel & {
      allPreferences: Edges<PreferenceModel>;
      allPreferenceProduct: Edges<PreferenceProductModel>;
      allGroupedPreferences: Edges<GroupedPreferenceModel>;
      allPromotedCampaignForBrands: Edges<PromotedCampaignForBrandsModel>;
    }
  >(graphql`
    query {
      allProductDb {
        edges {
          node {
            ...ProductDbFragment
          }
        }
      }
      site {
        ...SiteMetadataFragment
      }
      allPreferences {
        edges {
          node {
            ...PreferencesFragment
          }
        }
      }
      allGroupedPreferences {
        edges {
          node {
            ...GroupedPreferencesFragment
          }
        }
      }
      allPreferenceProduct {
        edges {
          node {
            ...PreferenceProductFragment
          }
        }
      }
      allPromotedCampaignForBrands {
        edges {
          node {
            ...PromotedCampaignForBrandsFragment
          }
        }
      }
    }
  `);

  const defaultBrand = firstSelector.brand;

  const [currentBrand, setCurrentBrand] = React.useState<Brand>(
    defaultBrand === 'default' ? site.siteMetadata.brand : defaultBrand
  );

  const allPreferenceProducts = allPreferenceProductsDb.edges.flatMap(
    edge => edge.node
  );
  const allPromotedCampaignsForBrand = allPromotedCampaignsForbrandsDb.edges.flatMap(
    edge => edge.node
  );

  // ToDo: This will come from API once migrations are merged
  const specialBoxes = ['GOURMET', 'GLUTEN'];

  const getProductSelectorForCurrentBrand = React.useCallback(() => {
    return model.productSelector.find(
      x => x.brand === currentBrand
    );
  }, [currentBrand, model.productSelector])

  const getDisplayType = React.useCallback(() => {
    const productSelectorForBrand = getProductSelectorForCurrentBrand();
    return productSelectorForBrand?.displayType ?? 'Preference';
  }, [getProductSelectorForCurrentBrand]);

  const allDisplayPreferences = (() => {
    const displayType = getDisplayType();

    if (displayType === 'Preference') {

      const allPreferences = allPreferencesDb.edges.flatMap(edge => edge.node);
      return allPreferences.map((pref) => {
        const recipes = pref.filters && pref.filters[0]
        return {...pref, recipes} as GroupedPreferenceModel
      })
      
    } else if (displayType === 'Preference Tiles' || displayType === 'Preference Nested' || displayType === 'Preference Flat'  || displayType === 'Proof Points') {
      const allPreferences = allGroupedPreferencesDb.edges.flatMap(edge => edge.node);
      return allPreferences;
    }
    return [];
  })();

  const recursiveMapDisplayPreferences = (preference: GroupedPreferenceModel): GroupedPreference => {
    return {
      brand: preference.brand,
      sortOrder: preference.sortOrder,
      preferenceId: preference.preferenceId ?? 0,
      displayName: preference.displayName,
      description: preference.description,
      preferenceName: preference.preferenceName,
      iconSvg: preference.iconSvg,
      state: StateType.default,
      DefaultProductCollectionCode: preference.defaultProductCollectionCode,
      pillBadge : preference.defaultProductCollectionCode === 'GOURMET' ? '+$3.99 Per Plate': undefined,
      childPreferences: preference.childPreferences?.map((childPref) => recursiveMapDisplayPreferences(childPref))
    } as GroupedPreference;
    }

  const flattenAllPreferences = (nodes: GroupedPreferenceModel[]): GroupedPreferenceModel[] => {
    let leafNodes: GroupedPreferenceModel[] = [];
  
    for (const node of nodes) {
      if (!node.childPreferences || node.childPreferences.length === 0) {
        leafNodes.push(node);
      } else {
        leafNodes = leafNodes.concat(flattenAllPreferences(node.childPreferences));
      }
    }
  
    return leafNodes;
  };

  const nestedAllPreferences = allDisplayPreferences.map(preference => recursiveMapDisplayPreferences(preference));
  const flattenedAllPreferences = flattenAllPreferences(allDisplayPreferences);

  const flattenedBrandFilteredPreferences = flattenedAllPreferences.filter(
    p => p.brand === currentBrand
  );
  const productModelCollection: Array<ProductModel> = allProductDb.edges
    .flatMap(edge => edge.node)
    .map(node =>
      getProductModel(
        {
          __typename: model.__typename,
          description: '',
          sku: node.sku,
          dinners: node.numberOfNights,
          people: node.numberOfPeopleToFeed,
          id: node.id,
          hideServingPrice: false,
          isSoldOut: false,
        },
        allProductDb,
        site.siteMetadata.gatewayUrl
      )
    )
    .filter((product): product is ProductModel => Boolean(product));

  const mappingService = new ProductsMappingService();

  const productCollectionGroupedByCode = allPreferenceProducts
    .filter(item => item.sku !== undefined)
    .reduce((acc: GroupedProducts, item) => {
      const {code, sku} = item;
      if (code && sku) {
        acc[code] = acc[code] || [];
        acc[code].push(sku);
      }
      return acc;
    }, {});

  // Manually add made skus as MADE donesn't exist in preference
  productCollectionGroupedByCode['MADE'] = [
    'K00000001',
    'K00000002',
    'K00000003',
    'K00000004',
  ];

  productCollectionGroupedByCode['DIABETES'] = [
    'K00000005',
    'K00000006',
    'K00000007',
    'K00000008',
    'K00000009',
    'K00000010',
  ]

  const productGroups = mappingService.assignProductModelsToCollection(
    productModelCollection,
    productCollectionGroupedByCode as GroupedProducts
  );

  // ToDo: Remove this hack.
  // Had to add this to make sure the Ready made tab doesnt bread because there is no data for ready made code in preference table.
  // And the API is only returning products whose collection code esists in preferences

  const bagSelectorService = new BagSelectorStateService(productGroups, [
    BRAND_GROUPS[Brand.RMM],
  ]);

  //const {preselectedPeople} = pageSettings;
  // ToDo: shoud be made configurable
  const preselectedPeople = 4;
  const productsByBrand = productGroups
    .filter(pg => pg.productGroupName === BRAND_GROUPS[currentBrand])
    .flatMap(x => x.products);
  const preSelectedProduct = productsByBrand.find(
    p => p.itemNumber === preSelectedSku
  );
  const applicableDefaultSkus = productGroups
    .filter(pg => pg.productGroupName === BRAND_GROUPS[currentBrand])
    .flatMap(x => x.products)
    .filter(x => x.serves === preselectedPeople);
  const defaultSku =
    preSelectedProduct?.sku ?? maxBy(applicableDefaultSkus, x => x.night)?.sku;
  const defaultNightsLabel = 'Meals per week';
  const rmmNightsLabel = 'Single serve meals per week';

  const getPrimaryButtontext = () => {
    const selectedProduct = getProductSelectorForCurrentBrand();
    return {
      desktop: selectedProduct?.primaryCallToActionText ?? 'Continue this plan',
      mobile: selectedProduct?.primaryCallToActionText ?? 'Continue',
    };
  };

  const getPreferenceSelectorVariant = () => {
    switch (getDisplayType()) {
      case 'Preference Tiles': return 'tiles';
      case 'Preference Nested': return 'nested';
      default: return 'default';
    }
  }

  const getPreSelectedPreferences = (currentBrand: Brand): string[] => {
    const displayType = getDisplayType();
    if (displayType === 'Preference'){
      if(typeof window !== 'undefined'){
        const queryPrefs = (
          new URLSearchParams(window.location.search).get('preference') || ''
        ).split(',');
  
        const queryPreferenceIds =  flattenedBrandFilteredPreferences
        .filter(x => x.brand === currentBrand && queryPrefs.includes(x.preferenceName??'') && x.preferenceId !== undefined)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(x => x.preferenceId!.toString())
        return queryPreferenceIds;
      }
      return [];
    }
    // return pre-selected preferences for proof points

    const productSelectorForBrand = getProductSelectorForCurrentBrand();
    
    const preferenceIds = flattenedBrandFilteredPreferences
      .filter(x => x.preferenceName === productSelectorForBrand?.preferencePreSelectedOption)
      .map(x => x.preferenceId?.toString());

      if (!preferenceIds){
        return []
      }
      return preferenceIds as string[]
  };

  const getIwsExcludedPreferences = (): Array<string> => {
    const productSelectorForBrand = getProductSelectorForCurrentBrand();
    return productSelectorForBrand?.excludePreferencesFromInWeekSales ?? [];
  }
  
  const [selectedPreferences, setSelectedPreferences] = React.useState<
    Array<string>
  >(getPreSelectedPreferences(currentBrand));

  const showBrandCampaign = () => {
    const productSelectorForBrand = getProductSelectorForCurrentBrand()
    return productSelectorForBrand?.showBrandCampaign ?? false;
  };

  const getBrandCampaignWithAdminCampaignId = React.useCallback(() : (string | undefined) => {
    const productSelectorForBrand = getProductSelectorForCurrentBrand();
    
    return productSelectorForBrand?.adminCampaignId;
  }, [getProductSelectorForCurrentBrand])

  const getProofPoints = () => {
    const productSelectorForBrand = getProductSelectorForCurrentBrand();
    const currentBrandProductSelector = productSelectorForBrand?.proofPoints?.map(
      x => mapHeroCardContentModelToProp(x, HeroCardType.ProofPoint)
    );
    return currentBrandProductSelector;
  };
  const enrolToCampaignByDefault = () =>
    getProductSelectorForCurrentBrand()?.enrollToCampaign ?? false;

  const hideRecipeCarouselForBrand = () =>
    getProductSelectorForCurrentBrand()?.hideRecipeCarousel ?? false;

  // Get Headings for sections
  const getSectionHeadings = (section: string) => {
    const selectedProduct = getProductSelectorForCurrentBrand();

    switch (section) {
      case 'heading':
        return selectedProduct?.heading ?? 'Tell us what you like';

      case 'preference':
        return (
          selectedProduct?.preferenceSectionHeading ?? 'Choose your preference'
        );

      case 'skuSelector':
        return selectedProduct?.nightPeopleSectionHeading ?? 'Select your plan';

      default:
        return 'Section not found'; // Optional: Default case for safety
    }
  };

  const [skuSelectorContext, setSkuSelectorContext] = React.useState<
    BagSelectorProps['skuSelectorContext']
  >({
    title: getSectionHeadings('skuSelector'),
    skuSelectorService: bagSelectorService,
    productsLabel: 'Tell us what you like.',
    nightsLabel: 'Meals per week',
    servingsLabel: 'Number of people',
    skuOverride: defaultSku,
    hideDisabled: {
      nights: true,
      servings: true,
    },
    proofPoints: getProofPoints(),
  });

  React.useEffect(() => {
    if (preSelectedBrand) {
      setCurrentBrand(preSelectedBrand as Brand);
    }
  }, [preSelectedBrand]);

  React.useEffect(() => {
    setSelectedPreferences(getPreSelectedPreferences(currentBrand));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrand]);

  const getSelectedPreferenceData = React.useCallback((selectedPreferences: string[]) => {
    return flattenedBrandFilteredPreferences.find(x =>
      selectedPreferences.includes(x.preferenceId?.toString() ?? '')
    ) as PreferenceModel;
  }, [flattenedBrandFilteredPreferences])

  const getSelectedPreferenceProductCollectionCode = (
    selectedPreferences: string[]
  ) => {
    const preference = flattenedBrandFilteredPreferences.find(x =>
      selectedPreferences.includes(x.preferenceId?.toString() ?? '')
    ) as PreferenceModel;

    //Hardcoding for diabetes, delete asap.
    if (preference?.preferenceName === 'MFBDiabetes') {
      return 'DIABETES'
    } else {
      return preference?.defaultProductCollectionCode; 
    }
  };

  const [
    skuSelectorProductOverride,
    setSkuSelectorProductOverride,
  ] = React.useState<string | undefined>(
    getSelectedPreferenceProductCollectionCode(selectedPreferences)
  );

  React.useEffect(() => {
    setSkuSelectorProductOverride(
      
      getSelectedPreferenceProductCollectionCode(selectedPreferences)
    );
    const isReadyMadeMeal = currentBrand === Brand.RMM;
    setSkuSelectorContext({
      ...skuSelectorContext,
      title: getSectionHeadings('skuSelector'),
      productOverride: skuSelectorProductOverride ?? BRAND_GROUPS[currentBrand],
      isReadyMadeMeal: isReadyMadeMeal,
      hideDisabled: {nights: isReadyMadeMeal, servings: isReadyMadeMeal},
      nightsLabel: isReadyMadeMeal ? rmmNightsLabel : defaultNightsLabel,
      proofPoints: getProofPoints(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreferences, skuSelectorProductOverride, currentBrand]);

  const [priceContext, setPriceContext] = React.useState<
    ProductPriceDisplayContext
  >();
  const getProductRecipes = React.useCallback(
    (itemNumber: string | undefined) => {
      const product = productModelCollection.find(
        _product => _product.itemNumber === itemNumber
      );
      // const productMetadata = find(
      //   PRODUCT_GROUPS,
      //   group => group.skus.findIndex(sku => sku === product?.itemNumber) >= 0
      // );
      const productRecipeCollections = product?.recipeCollections ?? [];
      const recipeCollections = flatten(
        productRecipeCollections.map(prc => prc.collections)
      );
      return {
        product,
        //productMetadata,
        recipeCollections,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [specialBoxSelected, setSpecialBoxSelected] = React.useState<boolean>(false);

  const onSkuChanged = React.useCallback(
    (newValue: BagSelectorProductResult | undefined) => {
      const {product, recipeCollections} = getProductRecipes(
        newValue?.itemNumber
      );
      if (specialBoxSelected) {
        setCarouselContext({
          ...carouselContext,
          recipeCollections,
        });
        setSkuSelectorContext({
          ...skuSelectorContext,
          //productsLabel: productMetadata?.blurb,
          isReadyMadeMeal: currentBrand === Brand.RMM,
          proofPoints: getProofPoints(),
          //valuePropositions: getValuePropositionsForCurrentBrand(),
        });
      }

      if (!product) {
        return;
      }
      const {price, serving} = calculateDiscount(discount, product);
      const productType = product.productType ?? ProductType.Primary;
      const hasDiscount = price.original !== price.discounted;
      const showServingPrice = Boolean(
        serving && productType === ProductType.Primary
      );
      const deliveryPrice = product.deliveryPrice;
      const hasDeliveryFee = deliveryPrice > 0;
      const totalPrice = (parseFloat(price.discounted) + deliveryPrice).toFixed(
        2
      );
      const internalPriceContext: ProductPriceDisplayContext = {
        showPricePerPlateDiscount: hasDiscount,
        showPricePerWeekDiscount: hasDiscount,
        pricePerWeek: `$${price.discounted}`,
        pricePerWeekBeforeDiscount: `$${price.original}`,
        pricePerPlate: showServingPrice ? `$${serving?.discounted}` : undefined,
        pricePerPlateBeforeDiscount: showServingPrice
          ? `$${serving?.original}`
          : undefined,
        deliveryPrice: hasDeliveryFee
          ? `$${deliveryPrice.toFixed(2)}`
          : undefined,
        totalPrice: hasDeliveryFee ? `$${totalPrice}` : undefined,
        primaryButtonText: 'Continue',
        pricePerPlateStringFormat: 'Per Plate',
        pricePerWeekStringFormat: 'Per Week',
      };
      setPriceContext(internalPriceContext);
      setSelectedProduct(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      model,
      discount,
      isLoading,
      getProductRecipes,
      currentBrand,
      specialBoxSelected,
      skuSelectorContext.productOverride,
    ]
  );

  const generateOrderLink = (sku: string) => {
    const product = productModelCollection.find(p => p.sku === sku);
    const isPrimary = Boolean(
      product && product.productType === ProductType.Primary
    );
    const extraPath = `${site.siteMetadata.accountUrl}/manage/subscriptions/extras/addbysku/${sku}`;
    const selectedPrefs = flattenedBrandFilteredPreferences
      .filter(sp =>
        selectedPreferences.find(p => p && sp && p === `${sp.preferenceId}`)
      )
      .filter(identity);
  
    const queryParams = new URLSearchParams();
    const campaignCode = brandCampaign;
    if (campaignCode) {
      queryParams.set('campaignCode', campaignCode);
    }
   
    const filteredSpecialBags = selectedPrefs.filter(
      sp =>
        !specialBoxes.includes(
          sp.defaultProductCollectionCode?.toString() ?? ''
        )
    );

    const selectedPreference = getSelectedPreferenceData(selectedPreferences);

    if(selectedPreference && selectedPreference.preferenceName && getIwsExcludedPreferences().includes(selectedPreference.preferenceName)){
      queryParams.set('ebd','1');
    }

    if (selectedPreference && selectedPreference.preferenceName === 'MFBDiabetes') {
      queryParams.set('har','1');
    }

    queryParams.set(
      'prefs',
      filteredSpecialBags.map(sp => sp.preferenceId).join(',')
    );
 
    queryParams.set('ss', '1');

    trackDataLayerEvent('add_to_cart', {
      campaign_code: campaignCode,
      subscription_people: product?.people.toString(),
      subscription_nights: product?.dinners.toString(),
      selected_brand: currentBrand,
      bag_sku: sku,
      preferences: selectedPrefs.map(sp => sp?.displayName).join('|'),
    });
    const queryParamString = queryParams.toString();
    const orderPath = getProductSelectEventPath(
      extraPath,
      isPrimary,
      product,
      queryParamString
    );
    return orderPath;
  };

  const onContinueToOrderForm = async () => {
    if (selectedProduct) {
      const target = generateOrderLink(selectedProduct.sku);
      window.open(target, '_self');
    }
  };
  const onPreferencesChanged = (
    _selectedPreferenceIds: string[] | number[] = []
  ) => {
    const preferenceIds = _selectedPreferenceIds.map(prefId =>
      prefId.toString()
    );
    const [addedPref] = difference(preferenceIds, selectedPreferences);
    const [removedPref] = difference(selectedPreferences, preferenceIds);
    if (!addedPref && !removedPref) {
      // Exit early if nothing changed.
      return;
    }
    const eventModifier = addedPref ? 'selected' : 'unselected';
    const modifiedId = eventModifier === 'selected' ? addedPref : removedPref;
    const modifiedPreference = flattenedBrandFilteredPreferences.find(
      pref => pref.preferenceId === Number(modifiedId)
    );
    const displayPrefs = flattenedBrandFilteredPreferences
      .filter(dp => preferenceIds.find(sp => sp === `${dp.preferenceId}`))
      .filter(identity);

    trackDataLayerEvent(`preferences_${eventModifier}`, {
      preferences: displayPrefs.map(pref => pref?.displayName).join('|'),
      preference_modified: modifiedPreference?.displayName,
    });

    setSelectedPreferences(preferenceIds);
    setSkuSelectorProductOverride(
      getSelectedPreferenceProductCollectionCode(preferenceIds)
    );
  };

  const getRecipesByPreference = React.useCallback(
    (preferenceIds: string[]) => {
      const activePreferences = flattenedBrandFilteredPreferences.filter(p =>
        preferenceIds.includes(`${p.preferenceId}`)
      );
      const brandHeroItemNumber =
        selectedProduct?.itemNumber;
      const recipeNumbers = activePreferences
        .flatMap(p => p.recipes)
        .flatMap(r => r?.items)
        .map(i => i?.itemNumber ?? '')
        // This filters out ''
        .filter(identity);
      const uniqueRecipeNumbers = uniq(recipeNumbers);
      return {
        heroSku: brandHeroItemNumber,
        recipeNumbers: uniqueRecipeNumbers,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProduct?.itemNumber]
  );

  React.useEffect(() => {
    if (!specialBoxSelected) {
      const {heroSku, recipeNumbers} = getRecipesByPreference(
        selectedPreferences
      );
      const {recipeCollections} = getProductRecipes(heroSku);
      const filteredRecipeCollections = recipeCollections.map(rc => {
        return {
          ...rc,
          recipes: sortByArray(rc.recipes, recipeNumbers, i => i.rnumber),
        };
      });
      setCarouselContext({
        ...carouselContext,
        recipeCollections: filteredRecipeCollections,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialBoxSelected, selectedPreferences, getRecipesByPreference]);

  const [campaignToggle, setCampaignToggle] = React.useState<boolean>(
    enrolToCampaignByDefault()
  );

  // ToDo: make this configurable.
  const getCurrentBrandCampaignCode = (
    isChecked: boolean,
    currenntBrand: Brand
  ) => {
    if (!isChecked) return undefined;
    return allPromotedCampaignsForBrand.find(x => x.brand === currenntBrand)
      ?.campaignCode;
  };

  const getCurrenCampaignCode = (
    isChecked: boolean,
    campaignCode: string
  ) => {
    if (!isChecked) return undefined;
    return campaignCode;
  }

  // Set campaign Code for brand whenver current brand updates
  const [brandCampaign, setBrandCampaign] = React.useState<string | undefined>(
    getCurrentBrandCampaignCode(
      enrolToCampaignByDefault(),
      currentBrand
    )
  );

  React.useEffect(() => {
    setBrandCampaign(
      getCurrentBrandCampaignCode(
        enrolToCampaignByDefault(),
        currentBrand
      )
    );
    setCampaignToggle(enrolToCampaignByDefault());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrand]);

  const [nonPromotedCampaignResponse, setNonPromotedCampaignResponse] = React.useState<NonPromotedCampaignsResponse | null>(null);
  const campaignCode = React.useRef<string | null>(null);

  // Trigger extra render for SSR
  React.useEffect(() => {
    if (model.productSelector.length > 0){
      const adminCampaignIdForBrand = getBrandCampaignWithAdminCampaignId();

      if (adminCampaignIdForBrand){
        fetchNonPromotedCampaigns(adminCampaignIdForBrand).then(response => {
          if (response?.isValidCampaign && response.brand === currentBrand) {
            campaignCode.current = adminCampaignIdForBrand;
            setNonPromotedCampaignResponse(response);
          
          if (campaignToggle){
            setBrandCampaign(getCurrenCampaignCode(campaignToggle, campaignCode.current ?? ''));
          }
        }
        });
      }
    }
  }, [campaignToggle, currentBrand, getBrandCampaignWithAdminCampaignId, model.productSelector]);

  const getCampaignToggleProps = (currentBrand: Brand) => {
    if (!showBrandCampaign()) return undefined;

    if (nonPromotedCampaignResponse != null && campaignCode.current != null) {
      const campaignToggleProps = {
        header: nonPromotedCampaignResponse.joinTitle,
        description: nonPromotedCampaignResponse.joinDescription,
        termsAndConditions: "T&C's apply",
        onChange: (isChecked: boolean) => {
          setCampaignToggle(isChecked);
          setBrandCampaign(getCurrenCampaignCode(isChecked, campaignCode.current ?? ''));
        },
        isChecked: campaignToggle,
      };

      return campaignToggleProps as CampaignToggleProps;
    }

    const currentBrandCampaignDescription = allPromotedCampaignsForBrand.find(
      x => x.brand === currentBrand
    );

    if (!currentBrandCampaignDescription) return undefined;

    const campaignToggleProps = {
      header: currentBrandCampaignDescription.joinTitle,
      description: currentBrandCampaignDescription.joinDescription,
      termsAndConditions: "T&C's apply",
      onChange: (isChecked: boolean) => {
        setCampaignToggle(isChecked);
        setBrandCampaign(getCurrentBrandCampaignCode(isChecked, currentBrand));
      },
      isChecked: campaignToggle,
    };

    return campaignToggleProps as CampaignToggleProps;
  };

  const props: BagSelectorProps = {
    title: firstSelector.heading,
    blurb: getSectionHeadings('heading'),
    hideRecipeCarousel: hideRecipeCarouselForBrand(),
    recipeCarouselContext: carouselContext,
    productPriceDisplayContext: priceContext,
    variant: BagSelectorLayoutVariant.mealPreferencesPreferenceFirst,
    primaryCtaLabel: getPrimaryButtontext(),
    reviews: (
      <RatingsWrapper>
        <Ratings />
      </RatingsWrapper>
    ),
    preferenceSelectorContext: {
      selectedBrand: currentBrand,
      title: getSectionHeadings('preference'),
      preferenceSelections: nestedAllPreferences,
      onClick: (pref: number) => onPreferencesChanged([pref]),
      variant: getPreferenceSelectorVariant(),
      useQueryParams: true,
      specialBagHeadingText: 'OR select Special Bags'
    },
    recipeSurchargeNotice:
      'Please note that certain recipes featured may be subject to a surcharge. Pricing information will be clearly displayed in your account area when selecting recipes.',
    mealPreferenceSelectorLayoutOptions: {
      isPrimaryCtaOffset: true,
      displayProofPoints: getDisplayType() === 'Proof Points',
    },
    skuSelectorContext,
    skuSelectorOnResult: onSkuChanged,
    campaignToggle: getCampaignToggleProps(currentBrand),
    productPricePrimaryOnClickAsync: onContinueToOrderForm,
  };
  return (
    <div
      className={classNames(
        'container-fluid',
        'd-flex',
        `justify-content-center p-0`
      )}
      {...optionalProps}
      style={{
        backgroundColor: 'transparent',
      }}
    >
      <div style={{width: '100%', maxWidth: '1370px'}}>
        <BrandSelector
          visible={model.productSelector.length > 1}
          selectedBrand={currentBrand}
          onClickCallback={brand => {
            setCurrentBrand(brand);
          }}
        >
          <StyledBagSelector {...props} className="px-4 my-0 my-md-3" />
        </BrandSelector>
      </div>
    </div>
  );
};
export default ContentfulProductSelectorTabset;
