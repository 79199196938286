import {Bold} from '@mfb/lego';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import {BaseTermSectionProps} from './BaseTermSectionProps';
import styles from './TermsSubsection.module.scss';
import {TermsSubsectionModel} from './TermsSubsectionModel';

const TermSubsection: React.FunctionComponent<TermsSubsectionModel &
  BaseTermSectionProps> = ({id, text, title, onSectionNumberAssignment}) => {
  const sectionNumber = onSectionNumberAssignment();

  return (
    <section key={id}>
      <Bold>
        <p id={`condition-${sectionNumber}`}>{`${sectionNumber}. ${title}`}</p>
      </Bold>
      <div className={`${styles.condition}`}>
        <ReactMarkdown
          source={text}
          allowedTypes={[
            'root',
            'text',
            'break',
            'paragraph',
            'emphasis',
            'strong',
            'thematicBreak',
            'blockquote',
            'delete',
            'link',
            'image',
            'linkReference',
            'imageReference',
            'list',
            'listItem',
            'heading',
          ]}
          unwrapDisallowed={true}
        />
      </div>
    </section>
  );
};

export default TermSubsection;
