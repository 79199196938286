import {
  AvailableFrequency,
  ProductGroupModel,
  ProductModel,
  ProductType,
} from '@mfb/cookbook';
import {CardTagProps, CheckboxProps, isNotNullOrUndefined} from '@mfb/lego';
import { first } from 'lodash';

import {getImageModel} from '../asset/AssetContentModel';
import {getColor} from '../color/ColorContentModel';
import {ProductGroupContentModel} from './ProductGroupContentModel';
import ProductGroupProteinContentModel from '../productGroupProtein/ProductGroupProteinContentModel';

export const getProductGroupModel = (
  model: ProductGroupContentModel,
  products: Array<ProductModel>
) => {
  const tag: CardTagProps = {
    text: model.tabText,
    color: getColor(model.tabColor),
  };

  const images =
    (model.images &&
      model.images.map(getImageModel).filter(isNotNullOrUndefined)) ||
    [];
  const recipeImages =
    (model.recipeImages &&
      model.recipeImages.map(getImageModel).filter(isNotNullOrUndefined)) ||
    [];

  const additionalPaths = model.additionalPaths
    ? model.additionalPaths.map(p => p.relativePath || '').filter(p => p !== '')
    : [];

  const firstProduct = first(products);
  const availableFreq = firstProduct && firstProduct.availableFrequency;

  const isPrimary =
    firstProduct && firstProduct.productType === ProductType.Primary;
  const isOneOff = availableFreq === AvailableFrequency.OneOff;

  const hideSelector = products.length === 1 && isPrimary && isOneOff;

  const productGroupProps: ProductGroupModel = {
    id: model.id,
    title: model.title,
    subtitle: model.subtitle,
    tag,
    images,
    products,
    defaultPath: model.relativePath.relativePath || '',
    additionalPaths,
    overcomingHesitation: model.overcomingHesitation,
    recipeImages,
    showSelector: !hideSelector,
    isCollapseDisabled: model.isCollapseDisabled,
    isSoldOut: model.isSoldOut,
    disclaimer: model.disclaimer,
    description: model.description,
    shouldHideSeeMenuLink: model.shouldHideSeeMenuLink,
  };

  return productGroupProps;
};

export const getProteinSelectors = (
  productGroupProtein: ProductGroupProteinContentModel[] | undefined,
  defaultProductGroupProteinNames : string[] | undefined
): CheckboxProps[] => {
  if (!productGroupProtein) return [];
  return productGroupProtein.map((pgp, index) => ({
    id: pgp.proteinName || index,
    label: pgp.description || 'No description available',
    iconUrl: pgp.image?.file.url, 
    variant: 'withImage',
    state: defaultProductGroupProteinNames?.some(d => d === pgp.proteinName) ? 'active' : 'default',
  } as CheckboxProps));
};
