/**
 * See:
 *  https://gp-cas.de/height-100vh-for-ios/.
 *  https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 * This sets the custom-property into the browser itself
 */

import {useEffect} from 'react';

import {isBrowser} from '../util/isBrowser';

/**
 * Classes with h-100vh has a calc field that is dependent on this
 * --vh value.
 */
const safariVHPolyFixEventListener = () => {
  if (!isBrowser) {
    return;
  }

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const useSafariVHPolyFix = () => {
  safariVHPolyFixEventListener();

  // We listen to the resize event so we can update the VH height in safari
  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    window.addEventListener('resize', safariVHPolyFixEventListener);

    return () => {
      window.removeEventListener('resize', safariVHPolyFixEventListener);
    };
  });
};
