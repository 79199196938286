import {
  Alignment,
  Carousel,
  getFlexAlignmentClasses,
  mapImageCarouselItem,
  PageSection,
} from '@mfb/lego';
import classnames from 'classnames';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import ActionButton, {ActionButtonProps} from '../ActionButton';
import {ImageModel} from '../ImageModel';
import {getRenderers} from './TwoColumnSectionUtils';

export interface TwoColumnSectionProps {
  id: string;
  title?: string;
  text: string;
  images: Array<ImageModel>;
  imageOnLeftLayout: boolean;
  button?: ActionButtonProps;
  backgroundColor?: string;
  textColor?: string;
  textAlignment: Alignment;
  skewed?: boolean;
}

const getColumnWidthClasses = (image: boolean, skewed?: boolean): string => {
  const base = 'col-12';

  if (skewed) {
    if (image) {
      return `${base} col-lg-8`;
    }

    return `${base} col-lg-4`;
  }

  return `${base} col-lg-6`;
};

export const TwoColumnSection: React.FunctionComponent<TwoColumnSectionProps> = ({
  title,
  text,
  button,
  images,
  imageOnLeftLayout,
  backgroundColor,
  id,
  textAlignment,
  textColor,
  skewed,
}) => {
  const renderers = getRenderers();

  // If this section has a title, then there should be enough spacing between the title and content, and between
  // adjacent page sections. But also, if no title exists, then the image should span the entire page section height
  // while padding added to only the text content. Setting pageSectionYSpace to undefined means default page section
  // vertical spacing is used.

  let pageSectionYSpace: string | undefined = 'py-0';
  let contentYSpace = 'py-4 py-lg-5';

  if (title) {
    pageSectionYSpace = undefined;
    contentYSpace = 'py-4 py-lg-0';
  }

  // If this section has a background color, then on mobile, there should be enough spacing between the content
  // div edge and the text. But if there is no background color, the text looks indented and out of line, so there
  // should not be any horizontal spacing. px-lg-4 is always required, to apply spacing between the image and text.

  let contentXSpace = 'px-0 px-lg-4';

  if (backgroundColor) {
    contentXSpace = 'px-3 px-lg-4';
  }

  const imageHeight = 70;
  const imagePaddingSize = {small: `${imageHeight}%`};

  return (
    <PageSection
      id={id}
      title={title}
      verticalSpaceClassName={pageSectionYSpace}
      childrenContainerClassName={
        imageOnLeftLayout ? '' : 'flex-lg-row-reverse'
      }
    >
      <div
        className={classnames(
          'px-0 d-flex flex-column',
          getFlexAlignmentClasses(Alignment.Center, 'col'),
          getColumnWidthClasses(true, skewed)
        )}
        style={{backgroundColor}}
      >
        <div className="w-100">
          <Carousel
            items={images.map(i => mapImageCarouselItem(i, imagePaddingSize))}
            itemClassName="d-block"
            showArrows
          />
        </div>
      </div>
      <div
        className={classnames(
          'd-flex flex-column',
          contentXSpace,
          contentYSpace,
          getColumnWidthClasses(false, skewed),
          getFlexAlignmentClasses(textAlignment, 'col')
        )}
        style={{color: textColor, backgroundColor}}
      >
        <ReactMarkdown
          className="w-100"
          source={text}
          allowedTypes={[
            'root',
            'text',
            'break',
            'paragraph',
            'emphasis',
            'strong',
            'thematicBreak',
            'blockquote',
            'delete',
            'link',
            'image',
            'linkReference',
            'list',
            'listItem',
            'heading',
          ]}
          unwrapDisallowed={true}
          renderers={renderers}
        />
        {button && <ActionButton {...button} />}
      </div>
    </PageSection>
  );
};
