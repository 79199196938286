import {Alignment, isNotNullOrUndefined, mapAlignment} from '@mfb/lego';
import * as React from 'react';

import {ImageModel} from '../../components/ImageModel';
import {TwoColumnSection} from '../../components/twoColumnSection/TwoColumnSection';
import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {getImageModel} from '../asset/AssetContentModel';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {TwoColumnSectionContentModel} from './TwoColumnSectionContentModel';

const ContentfulTwoColumnSection: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as TwoColumnSectionContentModel;

  const images: Array<ImageModel> = model.images
    .filter(i => i.large)
    .map(set => getImageModel(set.large))
    .filter(isNotNullOrUndefined);

  const button = model.callToAction && mapActionButton(model.callToAction);

  const text =
    (model.childContentfulTwoColumnSectionTextTextNode &&
      model.childContentfulTwoColumnSectionTextTextNode.text) ||
    '';

  const textAlignment = mapAlignment(model.textAlignment) || Alignment.Left;

  return (
    <TwoColumnSection
      id={model.id}
      backgroundColor={getColor(model.backgroundColor)}
      title={model.title}
      text={text}
      images={images}
      button={button}
      imageOnLeftLayout={model.imageOnLeftLayout}
      textAlignment={textAlignment}
      textColor={getColor(model.textColor)}
      skewed={model.skewed}
    />
  );
};

export default ContentfulTwoColumnSection;
