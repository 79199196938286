import {uniqueId} from 'lodash';

import {BreakPoints} from './PictureBreakpoints';

export const fallBackImageHost = '//via.placeholder.com';
export const fallbackImageSizes: any = {
  small: '576x300',
  medium: `${BreakPoints.Medium}x400`,
  large: `${BreakPoints.Large}x650`,
};

const id = uniqueId('picture');

export const fallbackImages = {
  large: {
    src: `${fallBackImageHost}/${fallbackImageSizes.large}`,
    id: `${id}-fallback-lg`,
  },
  medium: {
    src: `${fallBackImageHost}/${fallbackImageSizes.medium}`,
    id: `${id}-fallback-md`,
  },
  small: {
    src: `${fallBackImageHost}/${fallbackImageSizes.small}`,
    id: `${id}-fallback-sm`,
  },
};
