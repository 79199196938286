import {AvailableFrequency} from '@mfb/cookbook';
import {Brand} from '@mfb/lego';

import {
  ProductType,
  RecipeSelectionMode,
} from '../../gatsbyLib/data/CoreApiClient';
import {Edges} from './Edges';
import {ProductRecipeCollectionModel} from './ProductRecipeCollectionModel';

export interface ProductDbModel {
  id: string;
  name: string;
  sku: string;
  itemNumber: string;
  price: number;
  deliveryPrice: number;
  quantityPerLine: number;
  brand: Brand;
  link: string;
  productType: ProductType;
  availableFrequency: AvailableFrequency;
  recipeCollections?: Array<ProductRecipeCollectionModel>;
  recipeSelectionMode: RecipeSelectionMode;
  numberOfPeopleToFeed: number;
  numberOfNights: number;
  hasPreferences?: boolean;
}

export const findBySkuOrItemumber = (
  obj: Edges<ProductDbModel>,
  sku: string
): ProductDbModel | undefined => {
  const edge = obj && (obj.edges.find(p => p.node.sku === sku) ?? obj.edges.find(p => p.node.itemNumber === sku));

  return edge && edge.node;
};
