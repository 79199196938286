import {
  AvailableFrequency,
  LinkProps,
  ProductGroupCard,
  ProductGroupCardLayout,
  ProductGroupProps,
  ProductGroupSection,
  ProductGroupSelectorOptions,
  ProductModel,
  ProductType,
  StockActionType,
  useStock,
} from '@mfb/cookbook';
import { ProteinSelectorProps, isNotNullOrUndefined, Brand as LegoBrand } from '@mfb/lego';
import {graphql, StaticQuery} from 'gatsby';
import {first, isEqual, min} from 'lodash';
import * as React from 'react';

import {
  discountObjectStorageKey,
  discountStorageKey,
} from '../../templates/Layout';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {getProductModel} from '../product/getProductModel';
import {AllProductsQueryModel} from '../productCard/AllProductsQueryModel';
import {getProductGroupModel, getProteinSelectors} from './getProductGroupModel';
import {getProductSelectEventPath} from './getProductSelectEventPath';
import {ProductGroupContentModel} from './ProductGroupContentModel';
import { ApimClientConfiguration, WebsiteApiClient } from '../../../gatsbyLib/data/WebsiteApiClient';
import { fetchFromWebsiteApi } from '../../../gatsbyLib/data/fetchData';

interface ContentfulProductGroupRenderOption {
  renderAs?: 'card' | 'section';
}

type Props = ContentfulBaseProps &
  ContentfulProductGroupRenderOption &
  AllProductsQueryModel;

const PureContentfulProductGroup: React.FC<Props> = ({
  baseModel,
  pageSettings,
  renderAs,
  site,
  allProductDb,
}) => {
  const model = baseModel as ProductGroupContentModel;

  // Added this to retaine the skus after protein selection
  let queryParamSku : string | null = null;
  if(typeof window !== 'undefined'){
    queryParamSku = new URLSearchParams(window.location.search).get('sku');
  }

  const [products] = React.useState<Array<ProductModel>>(() =>
    model.products
      .map(p => getProductModel(p, allProductDb, site.siteMetadata.gatewayUrl))
      .filter(isNotNullOrUndefined)
  );

const [, dispatch] = useStock();


const [productSkusWithStockAvailable, setproductSkusWithStockAvailable] = React.useState<Array<string>>([]);

React.useEffect(() => {

  const getSelectedProductStock = async () =>{
    try {
      const config = new ApimClientConfiguration();
    
      config.setApiKey(process.env.GATSBY_WEBSITE_API_KEY as string, '', '')
    
      const client = new WebsiteApiClient(
        config,
        process.env.GATSBY_WEBSITE_API_URL,
        fetchFromWebsiteApi
      );

      const skus = products.map(x => x.sku).join(',');

      return  await client.productsStockByskusAvailability(skus).then(x => x);
    } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`Failed to load primary product addons. ${e.message}`);
    }
  }
  
  getSelectedProductStock().then(x => {
    const skus = x?.filter(y => y.hasStockAvailable).map(y => y.sku);
    setproductSkusWithStockAvailable(skus??[])})
},[])

const getSelectedProduct = (protienselections : string[], products: ProductModel[], selectedSku? : string) =>{

  // return first product if there is only one product
  if (products.length === 1){
    return first(products)
  }

  if (selectedSku){
    return first(products.filter(product => product.sku === selectedSku))
  }

  return first(products.filter(product => product.proteins &&
    protienselections.every(protein => product.proteins?.includes(protein))))?? first(products)
};

const productSelectEvent: LinkProps = {
  generateLink: (sku: string) => {
    const product = products.find(p => p.sku === sku);
    const extraPath = `${site.siteMetadata.accountUrl}/manage/subscriptions/extras/addbysku/${sku}`;

    return getProductSelectEventPath(extraPath, isPrimary, product);
  },
};

const getPreSelectedProtein = (model:ProductGroupContentModel, selectedSku?: string | null) =>{
  const product = first(products.filter(p => p.sku === selectedSku))
  if (selectedSku && product){
    return first(model.products.filter(x => x.sku === product.itemNumber))?.protein ?? []
  }

  const proteins = model.defaultProductGroupProteins?.map(x => x.proteinName).filter(isNotNullOrUndefined)?.sort() ??[]
  const availableProducts = products
  .filter(p => productSkusWithStockAvailable.includes(p.sku))
  .map(x => ({ sku: x.sku, itemNumber: x.itemNumber }));


  const availableContentFulProducts = model.products.filter(p => availableProducts.some(x => x.sku === p.sku || x.itemNumber === p.sku))

  const proteinProductAvailable = availableContentFulProducts.find(x => isEqual(x.protein?.sort(), proteins.sort()));

  if (proteins.length > 0 && availableContentFulProducts.length > 0 && !proteinProductAvailable){
    return(first(availableContentFulProducts)?.protein as string[])
  }
   return proteins
}

const updateSelectionOrder = (currentSelectionOrder: string[], newSelection: string[]) =>{
  const filteredSelectionArray = currentSelectionOrder.filter(item => newSelection.includes(item));

  // Find the elements in the new array that are not already in the filtered old array
  const newElements = newSelection.filter(item => !filteredSelectionArray.includes(item));

  // Concatenate the new elements to the filtered old array
  const updatedSelectionOrder = filteredSelectionArray.concat(newElements);

  return updatedSelectionOrder;
}

const [selectedProteins, setSelectedProteins] = React.useState<Array<string>>(getPreSelectedProtein(model, queryParamSku));
const [selectedProduct, setSelectedProduct] = React.useState(getSelectedProduct(selectedProteins, products));
const [selectionOrder, setSelectionOrder] = React.useState(selectedProteins);
const productGroupModel = getProductGroupModel(model, products.filter(x => x.sku === selectedProduct?.sku));

const proteinSelectionChanged = (newSelectedProteins : string[]) =>{
  if (isEqual(selectedProteins, newSelectedProteins.sort())) return;
  setSelectionOrder(updateSelectionOrder(selectedProteins, newSelectedProteins))
  setSelectedProteins(newSelectedProteins)
}

// update the proteins based on product stock
React.useEffect(() => {
  setSelectedProteins(getPreSelectedProtein(model, queryParamSku))
},[productSkusWithStockAvailable])

React.useEffect(() =>{
  setSelectedProduct(getSelectedProduct(selectedProteins, products))
}, [selectedProteins]);

const proteinSelectorProp = getProteinSelectors(model.productGroupProtein, selectedProteins)

const props: ProductGroupProps & ProductGroupSelectorOptions = {
  ...productGroupModel,
  description: productGroupModel.description,
  //basePrice: products.length > 1 && selectedProteins.length < 2 ? productGroupBasePrice: undefined,
  hidePrice:  products.length > 1 && selectedProteins.length < 2,
  // hardcoded max check count to 2 for now. ToDo: move this to contentful?
  proteinSelector: proteinSelectorProp ? {maxCheckCount: 2, proteinSelectors: proteinSelectorProp, proteinSelectionOrder: selectionOrder ,onClick: proteinSelectionChanged} as ProteinSelectorProps: undefined,
  preselectedPeople: pageSettings && pageSettings.preselectedPeople,
  preselectedDinners: pageSettings && pageSettings.preselectedDinners,
  productType: selectedProduct?.productType ?? ProductType.Primary,
  productSelectEvent,
  position: model.position,    
};

  const renderAsSection = renderAs === 'section' || false;

  const availableFreq = selectedProduct && selectedProduct?.availableFrequency;


  React.useEffect(() => {
    if (availableFreq && availableFreq === AvailableFrequency.OneOff) {
      products.forEach(p =>
        dispatch({type: StockActionType.registerOneOff, sku: p.sku})
      );
    }
  }, [availableFreq, dispatch, products]);

  React.useEffect(() => {
    if (availableFreq && availableFreq === AvailableFrequency.OneOff) {
      // xmas bag
      if (selectedProduct && selectedProduct.productType === ProductType.Primary) {
        // remove any promos
        localStorage.removeItem(discountStorageKey);
        localStorage.removeItem(discountObjectStorageKey);
      }
    }
  }, [availableFreq, selectedProduct]);

  const isPrimary = selectedProduct?.productType === ProductType.Primary;

  // filter out recipies with surcharge
  productGroupModel.products.map(p => {
    p.recipeCollections = p.recipeCollections?.map(rc => {
      rc.collections = rc.collections.map(c => {
        c.recipes = c.recipes.filter(r => r.surcharge === 0);

        return c;
      });

      return rc;
    });

    return p;
  });


  const overrideForExtraCards = {
    // Don't show selector and remove description
    showSelector: false,
    products: props.products.map((p: ProductModel) => ({
      ...p,
      description: '',
    })),
  };

  return renderAsSection ? (
    <>
      <ProductGroupSection {...props} isRecipeModalEnabled={true} />
    </>
  ) : (
    <ProductGroupCard
      {...props}
      {...(isPrimary ? {} : overrideForExtraCards)}
      cardType={
        ProductGroupCardLayout[
          model.cardType as keyof typeof ProductGroupCardLayout
        ]
      }
    />
  );
};

const ContentfulProductGroup: React.FunctionComponent<ContentfulBaseProps &
  ContentfulProductGroupRenderOption> = props => (
  <StaticQuery
    query={graphql`
      query {
        allProductDb {
          edges {
            node {
              ...ProductDbFragment
            }
          }
        }
        site {
          ...SiteMetadataFragment
        }
        features {
          ...FeaturesFragment
        }
      }
    `}
    render={(data: AllProductsQueryModel) => (
      <PureContentfulProductGroup {...props} {...data} />
    )}
  />
);

export default ContentfulProductGroup;
