import {BulletPointCard} from '@mfb/lego';
import * as React from 'react';

import {getImageModel} from '../asset/AssetContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import BulletPointCardContentModel from './BulletPointCardContentModel';

const ContentfulBulletPointCard: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as BulletPointCardContentModel;

  return (
    <BulletPointCard
      className="my-2"
      title={model.title}
      image={getImageModel(model.image)}
      pointsAsMarkdown={model.pointsAsMarkdown.pointsAsMarkdown.trim()}
    />
  );
};

export default ContentfulBulletPointCard;
