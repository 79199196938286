import {
  BagSelector,
  BagSelectorLayoutVariant,
  BagSelectorProductResult,
  BagSelectorProps,
  BagSelectorStateService,
  calculateDiscount,
  ProductModel,
  ProductPriceDisplayContext,
  ProductsMappingService,
  ProductType,
  RecipeCarouselContext,
  useDiscountContext,
} from '@mfb/cookbook';
import {Brand, BrandSelector, GroupedPreference, LegoBaseTheme, RecipeCarouselItemVariants, StateType,} from '@mfb/lego';
import {graphql, useStaticQuery} from 'gatsby';
import {difference, find, first, flatten, identity, mapValues, maxBy, uniq,} from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {getProductModel} from '../product/getProductModel';
import {AllProductsQueryModel} from '../productCard/AllProductsQueryModel';
import {getProductSelectEventPath} from '../productGroup/getProductSelectEventPath';
import BagSelectorContentModel from './BagSelectorContentModel';
import classNames from 'classnames';
import {Edges} from '../../customGraphQlModels/Edges';
import {PreferenceModel} from '../../customGraphQlModels/PreferenceModel';
import {Ratings} from '../../components/review/ratingsReviewsIo';
import {useAnalyticsTracking, useUtilFunctions} from '../../hooks';
import {handleCampaignToggle, handleCampaignToggleDefaultState, removeCampaignCodeFromUrl} from "./CampaignToggle";
import { getSelectedBrand, getSelectedSku } from './PreSelectedBag';
import { BagSelectorValuePropositionProps } from '@mfb/cookbook/dist/cookbook/src/component/bag-selector/BagSelectorValueProposition';

const BRAND_GROUPS = {
  [Brand.MFB]: 'My Choice',
  [Brand.RMM]: 'Ready Made',
  [Brand.FS]: 'Fresh Start',
  [Brand.BB]: 'Bargain Box',
};
export const enum QueryParams {
  campaignCode = 'campaignCode',
  selectedBrand = 'selectedBrand',
  sku = 'sku'
}

const HardPreferences = {
  PLANT_PWR_PREFERENCE_ID: '38',
  GLUTEN_FREE_PREFERENCE_ID: '40',
  GOURMET_PREFERENCE_ID: '41',
}

const LowCarbPreferenceId = '37';
const FsHighProteinPreferenceId = '49';
const FsSuperQuickPreferenceId = '50';

const ExcludeBufferDatePreferences = [LowCarbPreferenceId, FsHighProteinPreferenceId, FsSuperQuickPreferenceId]

const HardPreferenceProductGroups: Record<string, keyof typeof PRODUCT_GROUPS> = {
  38: 'Veggie & Plant Based',
  40: 'Gluten Free',
  41: 'Gourmet'
}

const PRODUCT_GROUPS = {
  'My Choice': {
    blurb: 'Something for everyone with 30+ delicious recipes.',
    skus: [
      'K00000005',
      'K00000008',
      'K00000006',
      'K00000009',
      'K00000007',
      'K00000010',
      'K00000041',
      'K00000042',
      'K00000043',
    ],
    valuePropositions: [],
  },
  'Fresh Start': {
    blurb: 'Deliciously healthy calorie-controlled meals for weight loss.',
    skus: [
      'K00000020',
      'K00000021',
      'K00000022',
      'K00000023',
      'K00000024',
      'K00000025',
      'K00000032',
      'K00000033',
      'K00000034',
    ],
    valuePropositions: [],
  },
  'Veggie & Plant Based': {
    blurb: 'A delicious variety of vegetarian and plant-based recipes.',
    skus: [
      'K00000026',
      'K00000027',
      'K00000028',
      'K00000029',
      'K00000030',
      'K00000031',
    ],
    valuePropositions: [],
  },
  'Bargain Box': {
    blurb: 'Choose from our range of family faves and crowd pleasers.',
    skus: [
      'K00000017',
      'K00000011',
      'K00000014',
      'K00000018',
      'K00000012',
      'K00000015',
      'K00000019',
      'K00000013',
      'K00000016',
    ],
    valuePropositions: [],
  },
  'Gluten Free': {
    blurb: 'Gluten free recipes.',
    skus: [
      'K00000049',
      'K00000050',
      'K00000051',
      'K00000052',
      'K00000053',
      'K00000054'
    ],
    valuePropositions: [],
  },
  'Gourmet': {
    blurb: 'Gourmet dishes.',
    skus: [
      'M00000019',
      'M00000157'
    ],
    valuePropositions: [],
  },  
  'Ready Made': {
    blurb: 'Fresh and fast ready made meals by My Food Bag.',
    skus: [
      'K00000001',
      'K00000002',
      'K00000003',
      'K00000004',
    ],
    valuePropositions: [{
      imageUrl: 'https://mfbstatic.blob.core.windows.net/value-propositions/made-meals-full-1.jpg',
      headerText: 'MADE for You',
      bodyText: 'Choose from 15+ meals each week to suit all tastes & lifestyles.'

    } as BagSelectorValuePropositionProps,
    {
      imageUrl: 'https://mfbstatic.blob.core.windows.net/value-propositions/made-meals-full-2.jpg',
      headerText: 'MADE Fresh',
      bodyText: 'Freshly made each week from local free range or free farmed ingredients.'

    } as BagSelectorValuePropositionProps,
    {
      imageUrl: 'https://mfbstatic.blob.core.windows.net/value-propositions/made-meals-full-3.jpg',
      headerText: 'MADE by Hand',
      bodyText: 'Handmade in New Zealand and delivered straight to your door.'
    } as BagSelectorValuePropositionProps]
  }
};

const StyledBagSelector = styled(BagSelector)`
  && {
    /** Add margin to the Button Choices! */
    [role='toolbar'] {
      &.btn-toolbar {
        @media ${LegoBaseTheme.layout.deviceMediaQueries.min.lg} {
          margin-right: 3em;
        }
      }
    }
  }
`;

const RatingsWrapper = styled.div`
  margin-top: 48px;
  @media ${(c) => c.theme.layout.deviceMediaQueries.max.md} {
    margin-top: 32px;
  }
`;
const ContentfulBagSelector: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
  pageSettings = {},
}) => {
  const brand = process.env.GATSBY_BRAND;
  const {sortByArray} = useUtilFunctions();
  const model = baseModel as BagSelectorContentModel;
  const preSelectedSku = getSelectedSku();
  const preSelectedBrand = getSelectedBrand();

  const optionalProps: Record<string, number | string | boolean> = {};
  const isMealPreferences = model.bagSelectorVariant !== BagSelectorLayoutVariant.default;
  const [selectedHardPreference, setSelectedHardPreference] = React.useState<keyof typeof PRODUCT_GROUPS>();
  if (model.abTestId) {
    optionalProps['data-ab-test-id'] = model.abTestId;
  }

  if (model.isHidden) {
    optionalProps['role'] = 'presentation';
  }
  const [carouselContext, setCarouselContext] = React.useState<
    RecipeCarouselContext
  >({
    recipeCollections: [],
    showDateSelector: model.carouselWeekSelector ?? false,
    disableDetails: model.carouselDisableRecipeDetail ?? false,
    carouselItemVariant:
      first(model.carouselItemStyle) ?? RecipeCarouselItemVariants.Default,
    showEmptySrcImages: false,
  });
  const {discount, isLoading} = useDiscountContext();
  const {trackDataLayerEvent} = useAnalyticsTracking();
  const [selectedProduct, setSelectedProduct] = React.useState<
    BagSelectorProductResult
  >();
  const [selectedPreferences, setSelectedPreferences] = React.useState<
    Array<string>
  >([]);

  const {allProductDb, site, allPreferences: allPreferencesDb} = useStaticQuery<
    AllProductsQueryModel & {
      allPreferences: Edges<PreferenceModel>;
    }
  >(graphql`
    query {
      allProductDb {
        edges {
          node {
            ...ProductDbFragment
          }
        }
      }
      site {
        ...SiteMetadataFragment
      }
      allPreferences {
        edges {
          node {
            ...PreferencesFragment
          }
        }
      }
    }
  `);
  
  const defaultBrand = model.brand;
  const fsCampaignCode = "e88abcd7-6ca8-4334-90a0-2b27658629a8";
  const bbCampaignCode = "0defb70f-d802-4432-a464-dc21106ce827"
  const [currentBrand, setCurrentBrand] = React.useState<Brand>(
    defaultBrand === 'default' ? site.siteMetadata.brand : defaultBrand
  );
  const BrandPreferenceMapping = {
    [Brand.BB]: 'BARGAIN',
    [Brand.FS]: 'FRESH',
    [Brand.MFB]: 'MFB',
    [Brand.RMM]: 'RMM',
  };

  const allPreferences = allPreferencesDb.edges.flatMap(edge => edge.node);

  const allDisplayPreferences = allPreferences.map(
    (preference: PreferenceModel) =>
    {
      let pillBadge = undefined;
      
      if (preference.preferenceId === Number(HardPreferences.GOURMET_PREFERENCE_ID)) {
        pillBadge = 'Premium option';
      } else if (preference.preferenceId === Number(HardPreferences.GLUTEN_FREE_PREFERENCE_ID)) {
        pillBadge = 'NEW'
      }

      return {
        brand: preference.brand,
        sortOrder: preference.sortOrder,
        preferenceId: preference.preferenceId ?? 0,
        displayName: preference.displayName,
        description: preference.description,
        preferenceName: preference.preferenceName,
        iconSvg: preference.iconSvg,
        state: StateType.default,
        pillBadge: pillBadge
      } as GroupedPreference}
  );
  
  const brandedPreferences = allPreferences.filter(
    p => p.brand === BrandPreferenceMapping[currentBrand]
  );
  const productModelCollection: Array<ProductModel> = allProductDb.edges
    .flatMap(edge => edge.node)
    .map(node =>
      getProductModel(
        {
          __typename: model.__typename,
          description: '',
          sku: node.sku,
          dinners: node.numberOfNights,
          people: node.numberOfPeopleToFeed,
          id: node.id,
          hideServingPrice: false,
          isSoldOut: false,
        },
        allProductDb,
        site.siteMetadata.gatewayUrl
      )
    )
    .filter((product): product is ProductModel => Boolean(product));
  const productSkus = mapValues(PRODUCT_GROUPS, valueProp =>
    model.disabledSkus
      ? valueProp.skus.filter(c => !model.disabledSkus.includes(c))
      : valueProp.skus
  );

  const mappingService = new ProductsMappingService();
  const productGroups = mappingService.groupProductModelCollectionByItemNumber(
    productModelCollection,
    productSkus
  );

  const bagSelectorService = new BagSelectorStateService(productGroups, [BRAND_GROUPS[Brand.RMM]]);

  const {preselectedPeople} = pageSettings;
  const productsByBrand = productGroups
    .filter(pg => pg.productGroupName === BRAND_GROUPS[currentBrand])
    .flatMap(x => x.products)
  const preSelectedProduct = productsByBrand.find(p => p.itemNumber === preSelectedSku);
  const applicableDefaultSkus = productGroups
    .filter(pg => pg.productGroupName === BRAND_GROUPS[currentBrand])
    .flatMap(x => x.products)
    .filter(x => x.serves === preselectedPeople);
  const defaultSku = preSelectedProduct?.sku ?? maxBy(applicableDefaultSkus, x => x.night)?.sku;

  const defaultNightsLabel = isMealPreferences ? 'Meals per week' : 'How many nights?'
  const rmmNightsLabel = 'Single serve meals per week'


  //  BagSelector doesn't really handle the brand, so pull the ValuePropositions from PRODUCT_GROUPS here based
  //  on current brand for BagSelector to display iff displayValuePropositions in layout options is true.
  const getValuePropositionsForCurrentBrand = () => {
    const productGroupsArray = Object.entries(PRODUCT_GROUPS).map(
      ([key, value]) => {
        return {groupName: key, data: value};
      }
    );

    return productGroupsArray.find(
      c => c.groupName === BRAND_GROUPS[currentBrand]
    )?.data.valuePropositions;
  };

  const [skuSelectorContext, setSkuSelectorContext] = React.useState<
    BagSelectorProps['skuSelectorContext']
  >({
    title: 'Select your plan',
    skuSelectorService: bagSelectorService,
    productsLabel: 'Tell us what you like.',
    nightsLabel: isMealPreferences ? 'Meals per week' : 'How many nights?',
    servingsLabel: isMealPreferences ? 'Number of people' : 'Number of people?',
    skuOverride: defaultSku,
    hideDisabled: {
      nights: model.hideDisabledStateForNights,
      servings: model.hideDisabledStateForServings,
    },
    valuePropositions: getValuePropositionsForCurrentBrand()
  });

  React.useEffect(() => {
    if(preSelectedBrand){
      setCurrentBrand(preSelectedBrand as Brand)
    }
  }, [preSelectedBrand])

  React.useEffect(() => {
    const isReadyMadeMeal = currentBrand === Brand.RMM;
    setSkuSelectorContext({
      ...skuSelectorContext,
      productOverride: selectedHardPreference || BRAND_GROUPS[currentBrand],
      isReadyMadeMeal: isReadyMadeMeal,
      hideDisabled: {nights: isReadyMadeMeal, servings: isReadyMadeMeal},
      nightsLabel: isReadyMadeMeal ? rmmNightsLabel: defaultNightsLabel,
      valuePropositions: getValuePropositionsForCurrentBrand()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHardPreference, currentBrand]);

  const [priceContext, setPriceContext] = React.useState<
    ProductPriceDisplayContext
  >();
  const getProductRecipes = React.useCallback(
    (itemNumber: string | undefined) => {
      const product = productModelCollection.find(
        _product => _product.itemNumber === itemNumber
      );
      const productMetadata = find(
        PRODUCT_GROUPS,
        group => group.skus.findIndex(sku => sku === product?.itemNumber) >= 0
      );
      const productRecipeCollections = product?.recipeCollections ?? [];
      const recipeCollections = flatten(
        productRecipeCollections.map(prc => prc.collections)
      );
      return {
        product,
        productMetadata,
        recipeCollections,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSkuChanged = React.useCallback(
    (newValue: BagSelectorProductResult | undefined) => {
      const {product, productMetadata, recipeCollections} = getProductRecipes(
        newValue?.itemNumber
      );

      if (!isMealPreferences || selectedHardPreference) {
        setCarouselContext({
          ...carouselContext,
          recipeCollections,
        });
        setSkuSelectorContext({
          ...skuSelectorContext,
          productsLabel: model.productBlurb || productMetadata?.blurb,
          isReadyMadeMeal: currentBrand === Brand.RMM,
          valuePropositions: getValuePropositionsForCurrentBrand()
        });
        
      }

      if (!product) {
        return;
      }
      const {price, serving} = calculateDiscount(discount, product);
      const productType = product.productType ?? ProductType.Primary;
      const hasDiscount = price.original !== price.discounted;
      const showServingPrice = Boolean(
        serving && productType === ProductType.Primary
      );
      const deliveryPrice = product.deliveryPrice;
      const hasDeliveryFee = deliveryPrice > 0;
      const totalPrice = (parseFloat(price.discounted) + deliveryPrice).toFixed(
        2
      );
      const internalPriceContext: ProductPriceDisplayContext = {
        showPricePerPlateDiscount: hasDiscount,
        showPricePerWeekDiscount: hasDiscount,
        pricePerWeek: `$${price.discounted}`,
        pricePerWeekBeforeDiscount: `$${price.original}`,
        pricePerPlate: showServingPrice ? `$${serving?.discounted}` : undefined,
        pricePerPlateBeforeDiscount: showServingPrice
          ? `$${serving?.original}`
          : undefined,
        deliveryPrice: hasDeliveryFee
          ? `$${deliveryPrice.toFixed(2)}`
          : undefined,
        totalPrice: hasDeliveryFee ? `$${totalPrice}` : undefined,
        primaryButtonText: model.productPurchaseCtaText ?? 'Continue',
        pricePerPlateStringFormat: 'Per Plate',
        pricePerWeekStringFormat: 'Per Week',
      };
      setPriceContext(internalPriceContext);
      setSelectedProduct(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model, discount, isLoading, getProductRecipes, currentBrand, selectedHardPreference, skuSelectorContext.productOverride]
  );
  
  const generateOrderLink = (sku: string) => {
    const product = productModelCollection.find(p => p.sku === sku);
    const isPrimary = Boolean(
      product && product.productType === ProductType.Primary
    );
    const extraPath = `${site.siteMetadata.accountUrl}/manage/subscriptions/extras/addbysku/${sku}`;
    const selectedPrefs = brandedPreferences
      .filter(sp =>
        selectedPreferences.find(p => p && sp && p === `${sp.preferenceId}`)
      )
      .filter(identity);
    const queryParams = new URLSearchParams();
    const campaignCode = getCampaignCode();
    if (campaignCode) {
      queryParams.set('campaignCode', campaignCode);
    }

    if(selectedPreferences.some(p => ExcludeBufferDatePreferences.includes(p))){
      queryParams.set('ebd','1');
    }

    if (isMealPreferences) {
      const filteredGourmet = selectedPrefs.filter(sp => `${sp.preferenceId}` !== HardPreferences.GOURMET_PREFERENCE_ID);

      queryParams.set(
        'prefs',
        filteredGourmet.map(sp => sp.preferenceId).join(',')
      );
      queryParams.set('ss', '1');
    }
    trackDataLayerEvent('add_to_cart', {
      campaign_code: campaignCode,
      subscription_people: product?.people.toString(),
      subscription_nights: product?.dinners.toString(),
      selected_brand: currentBrand,
      bag_sku: sku,
      preferences: selectedPrefs.map(sp => sp?.displayName).join('|'),
    });
    const queryParamString = queryParams.toString();
    const orderPath = getProductSelectEventPath(
      extraPath,
      isPrimary,
      product,
      queryParamString
    );
    return orderPath;
  };

  const generateOrderFormLink = (sku: string) => {
    return `/signup/personal-details?sku=${sku}`;
  };
  const getCampaignCode = () => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const camapaignCode = params.get('campaignCode');
    return camapaignCode;
  };

  const onContinueToOrderForm = async () => {
    if (selectedProduct) {
      const target = model.useV4OrderForm
        ? generateOrderFormLink(selectedProduct.sku)
        : generateOrderLink(selectedProduct.sku);
      window.open(target, '_self');
    }
  };
  const onPreferencesChanged = (
    _selectedPreferenceIds: string[] | number[] = []
  ) => {
    const preferenceIds = _selectedPreferenceIds.map(prefId =>
      prefId.toString()
    );
    const [addedPref] = difference(preferenceIds, selectedPreferences);
    const [removedPref] = difference(selectedPreferences, preferenceIds);
    if (!addedPref && !removedPref) {
      // Exit early if nothing changed.
      return;
    }
    const eventModifier = addedPref ? 'selected' : 'unselected';
    const modifiedId = eventModifier === 'selected' ? addedPref : removedPref;
    const modifiedPreference = allDisplayPreferences.find(
      pref => pref.preferenceId === Number(modifiedId)
    );
    const displayPrefs = allDisplayPreferences
      .filter(dp => preferenceIds.find(sp => sp === `${dp.preferenceId}`))
      .filter(dp => dp && dp.brand === BrandPreferenceMapping[currentBrand])
      .filter(identity);
    trackDataLayerEvent(`preferences_${eventModifier}`, {
      preferences: displayPrefs.map(pref => pref?.displayName).join('|'),
      preference_modified: modifiedPreference?.displayName,
    });

    if (model.bagSelectorVariant === 'singleSelectHardPreference') {
      setSelectedHardPreference('Veggie & Plant Based');

      if (preferenceIds.includes(HardPreferences.PLANT_PWR_PREFERENCE_ID)) {
        setSelectedHardPreference(
          HardPreferenceProductGroups[HardPreferences.PLANT_PWR_PREFERENCE_ID]
        );
      } else if (
        preferenceIds.includes(HardPreferences.GLUTEN_FREE_PREFERENCE_ID)
      ) {
        setSelectedHardPreference(
          HardPreferenceProductGroups[HardPreferences.GLUTEN_FREE_PREFERENCE_ID]
        );
      } else if (
        preferenceIds.includes(HardPreferences.GOURMET_PREFERENCE_ID)
      ) {
        setSelectedHardPreference(
          HardPreferenceProductGroups[HardPreferences.GOURMET_PREFERENCE_ID]
        );
      } else {
        setSelectedHardPreference(undefined);
      }
    } else {
      setSelectedHardPreference(undefined);
    }

    setSelectedPreferences(preferenceIds);
  };

  const getRecipesByPreference = React.useCallback(
    (preferenceIds: string[]) => {
      const activePreferences = brandedPreferences.filter(p =>
        preferenceIds.includes(`${p.preferenceId}`)
      );
      const brandHeroItemNumber =
        selectedProduct?.itemNumber;
      const recipeNumbers = activePreferences
        .flatMap(p => p.filters)
        .flatMap(f => f?.recipes)
        .flatMap(r => r?.items)
        .map(i => i?.itemNumber ?? '')
        // This filters out ''
        .filter(identity);
      const uniqueRecipeNumbers = uniq(recipeNumbers);
      return {
        heroSku: brandHeroItemNumber,
        recipeNumbers: uniqueRecipeNumbers,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProduct?.itemNumber]
  );

  React.useEffect(() => {
    if (isMealPreferences && !selectedHardPreference) {
      const {heroSku, recipeNumbers} = getRecipesByPreference(
        selectedPreferences
      );
      const {recipeCollections} = getProductRecipes(heroSku);
      const filteredRecipeCollections = recipeCollections.map(rc => {
        return {
          ...rc,
          recipes: sortByArray(rc.recipes, recipeNumbers, i => i.rnumber),
        };
      });
      setCarouselContext({
        ...carouselContext,
        recipeCollections: filteredRecipeCollections,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPreferences, getRecipesByPreference, isMealPreferences]);

  const getBlurb = () => {
    if (brand === Brand.MFB && isMealPreferences) {
      const productGroupsArray = Object.entries(PRODUCT_GROUPS).map(
        ([key, value]) => {
          return {groupName: key, data: value};
        }
      );
      return productGroupsArray.find(
        c => c.groupName === BRAND_GROUPS[currentBrand]
      )?.data.blurb;
    }
  };

  //Default campaign toggle to ON for BargainBox loyalty.
  // React.useEffect(() => {
  //   if (currentBrand === Brand.BB) {
  //     handleCampaignToggle(bbCampaignCode, true);
  //     updateToggleUi();
  //   }
  // }, [currentBrand])

  const [fsCampaignToggled, setFsCampaignToggled] = React.useState<boolean>(handleCampaignToggleDefaultState(fsCampaignCode));
  const [bbCampaignToggled, setBbCampaignToggled] = React.useState<boolean>(handleCampaignToggleDefaultState(bbCampaignCode));

  const updateToggleUi = () => {
    setFsCampaignToggled(handleCampaignToggleDefaultState(fsCampaignCode))
    setBbCampaignToggled(handleCampaignToggleDefaultState(bbCampaignCode))
  }

  let campaignToggleProps = undefined;
  if (currentBrand === "FS") { campaignToggleProps = {
    ribbonText: "GET RESULTS FASTER",
    header: "Join The 8-week Reset",
    description: "Get 50% off your 8th bag",
    termsAndConditions: "T&C's apply",
    onChange: (isChecked: boolean) => {
      handleCampaignToggle(fsCampaignCode, isChecked);
      updateToggleUi();
    },
    isChecked: fsCampaignToggled,
  }} 
  // else if (currentBrand === "BB") {
  //   campaignToggleProps = {
  //     ribbonText: "MORE SAVINGS",
  //     header: "Join Spend & Save",
  //     description: "Earn $ rewards for every 3 deliveries - get up to $100 off!",
  //     termsAndConditions: "Ts & Cs apply",
  //     onChange: (isChecked: boolean) => {
  //       handleCampaignToggle(bbCampaignCode, isChecked);
  //       updateToggleUi();
  //     },
  //     isChecked: bbCampaignToggled,
  //   }
  // }

  const props: BagSelectorProps = {
    title: isMealPreferences ? model.title : "Let's get cooking!",
    blurb: getBlurb(),
    recipeCarouselContext: carouselContext,
    productPriceDisplayContext: priceContext,
    variant: model.bagSelectorVariant,
    primaryCtaLabel: {desktop: 'Continue this plan', mobile: 'Continue'},
    reviews: (
      <RatingsWrapper>
        <Ratings />
      </RatingsWrapper>
    ),
    preferenceSelectorContext: {
      selectedBrand: currentBrand,
      title: 'Choose your preference',
      preferenceSelections: allDisplayPreferences,
      onClick: (pref: number) => onPreferencesChanged([pref]),
      useQueryParams: true,
    },
    recipeSurchargeNotice:
      'Please note that certain recipes featured may be subject to a surcharge. Pricing information will be clearly displayed in your account area when selecting recipes.',
    mealPreferenceSelectorLayoutOptions: {
      isPrimaryCtaOffset: true,
      displayValuePropositions: true,
    },
    skuSelectorContext,
    skuSelectorOnResult: onSkuChanged,
    campaignToggle: campaignToggleProps,
    productPricePrimaryOnClickAsync: onContinueToOrderForm,
  };
  return (
    <div
      className={classNames(
        'container-fluid',
        model.isHidden ? 'd-none' : 'd-flex',
        `justify-content-center p-0`
      )}
      {...optionalProps}
      style={{
        backgroundColor: isMealPreferences
          ? 'transparent'
          : getColor(model.backgroundColor),
      }}
    >
      <div style={{width: '100%', maxWidth: '1370px'}}>
        <BrandSelector
          visible={
            defaultBrand !== 'default' &&
            model.bagSelectorVariant !== BagSelectorLayoutVariant.default
          }
          selectedBrand={currentBrand}
          onClickCallback={(brand)=> {
            if(brand !== Brand.FS && getCampaignCode() === fsCampaignCode) {
              removeCampaignCodeFromUrl(fsCampaignCode);
              updateToggleUi();
            }
            if(brand !== Brand.BB && getCampaignCode() === bbCampaignCode) {
              removeCampaignCodeFromUrl(bbCampaignCode);
              updateToggleUi();
            }
            setCurrentBrand(brand)
          }}
        >
          <StyledBagSelector {...props} className="px-4 my-0 my-md-3" />
        </BrandSelector>
      </div>
    </div>
  );
};
export default ContentfulBagSelector;
