import {
  CookbookStrategy,
  CookbookStrategyContext,
  RecipeDetailModel,
  RecipeDetailModelLegacy,
  RecipeDetailedCardBody,
  RecipeModal,
  RecipeModel,
  mapRecipeResponseToDetailedCardProps,
} from '@mfb/cookbook';
import {
  Alignment,
  Brand,
  Carousel,
  DetailedCardContext,
  DetailedCardContextProps,
  DetailedCardHeader,
  DetailedCardModal,
  mapRecipeCarouselItem,
  RecipeCarouselItemProps,
} from '@mfb/lego';
import classnames from 'classnames';
import {first, flatten, kebabCase} from 'lodash';
import * as React from 'react';
import LazyLoad from 'react-lazyload';
import { RecipeCollectionModel } from '../../customGraphQlModels/ProductRecipeCollectionModel';

const ARROW_PROPS = {
  arrowClassNames: 'mx-0 mx-md-1',
  arrowDistanceFromTop: '43%',
};

const mapRecipeToCarouselItem = (
  recipe: RecipeModel,
  onClick?: (
    recipeNumber?: string,
    recipeVersion?: string,
    partition?: string,
    recipeId?: number
  ) => void
): RecipeCarouselItemProps => ({
  id: recipe.id,
  src: recipe.src || '',
  alt: recipe.name,
  caption: recipe.name,
  showCaption: true,
  captionAlignment: Alignment.Left,
  showShadow: true,
  surcharge: recipe.surcharge,
  onClick:
    onClick &&
    (() =>
      onClick(recipe.rnumber, recipe.rversion, recipe.partition, recipe.rid)),
});

export interface RecipeDisplayProps {
  recipeCollections: Array<RecipeCollectionModel>;
  responsiveSlideCount?: any;
}

export const RecipeDisplay: React.FC<RecipeDisplayProps> = props => {
  const [selectedDate, setDate] = React.useState(
    first(props.recipeCollections.map(rbw => rbw.day))
  );

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalModel, setModalModel] = React.useState<DetailedCardContextProps>();
  const context: CookbookStrategy = React.useContext(CookbookStrategyContext);

  const brand = process.env.GATSBY_BRAND;

  const onRecipeClick = React.useCallback(
    (
      recipeNumber?: string,
      recipeVersion?: string,
      partition?: string,
    ) => {
      setIsModalOpen(true);
      setModalModel(undefined);

      const deliveryWeek = props.recipeCollections && props.recipeCollections[0]?.date.toString();
      // eslint-disable-next-line no-unused-expressions
      context &&
        context.getRecipeDetailByWeek?.(recipeNumber, recipeVersion, partition, deliveryWeek ? new Date(deliveryWeek) : new Date())
          .then((response) =>{
            setModalModel(mapRecipeResponseToDetailedCardProps(response, false));
          });
    },

    // eslint-disable-next-line @typescript-eslint/unbound-method
    [context]
  );

  const memoedCarousel = React.useMemo(() => {
    const filtered = props.recipeCollections
      .filter(({day}) => day === selectedDate)
      .map(rc => {
        rc.recipes = rc.recipes.filter(r => r.surcharge === 0);

        return rc;
      });

    const displayImages = flatten(
      filtered.map((rc: RecipeCollectionModel) => {
        const recipes = rc.recipes.filter(r => r.src);

        return recipes.map(r =>
          mapRecipeToCarouselItem(r, () =>
            onRecipeClick(r.rnumber, r.rversion, r.partition)
          )
        );
      })
    );

    const carouselItems = displayImages.map(item =>
      mapRecipeCarouselItem(item)
    );

    return (
      <Carousel
        items={carouselItems}
        infinite={false}
        responsiveSlideCount={
          props.responsiveSlideCount || {
            small: 1.5,
            medium: 1.5,
            large: 1.5,
            extraLarge: 1.5,
          }
        }
        showArrows={true}
        itemClassName="px-2"
        arrowProps={ARROW_PROPS}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, props.recipeCollections]);

  return (
    <>
      {
        modalModel && (
          <DetailedCardModal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            headerTitle={'Meal Details'}
          >
            <DetailedCardContext.Provider value={modalModel}>
              <DetailedCardHeader actionBar={undefined} />
              <RecipeDetailedCardBody />
            </DetailedCardContext.Provider>
          </DetailedCardModal>
        )
      }
      <div className="d-flex mb-4">
        {props.recipeCollections.map(rbw => {
          const isSelectedDay = selectedDate === rbw.day;
          const tabClass = classnames(
            'mx-2 border-primary',
            isSelectedDay && 'border-bottom'
          );
          const textClass = isSelectedDay ? 'text-primary' : '';

          return (
            <div
              style={{cursor: 'pointer'}}
              onClick={() => setDate(rbw.day)}
              key={`day-${rbw.id}`}
              className={tabClass}
              data-role="tab"
              data-test={`RecipeWeekSelector-tab-${kebabCase(rbw.day)}`}
            >
              <span
                className={
                  brand === Brand.FS ? 'fs-text-dark-color-override' : textClass
                }
                data-test={`RecipeWeekSelector-tab-text`}
              >
                {rbw.day}
              </span>
            </div>
          );
        })}
      </div>
      <div className="w-100">
        <LazyLoad>{memoedCarousel}</LazyLoad>
      </div>
    </>
  );
};
