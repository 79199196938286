import {Dictionary} from '@mfb/lego';
import * as React from 'react';

import ClickableDiv from './ClickableDiv';
import {ImageModel} from './ImageModel';
import {mfbScrollSmooth} from './MFBScroll';
import Picture from './Picture';

interface SimpleJumbotronProps {
  imageSet: Dictionary<ImageModel>;
  altText?: string;
  link?: string;
  scrollTarget?: string;
}

const SimpleJumbotron: React.FC<SimpleJumbotronProps> = ({
  imageSet,
  altText,
  link,
  scrollTarget,
}) =>
  link ? (
    <ClickableDiv link={link}>
      <Picture imageSet={imageSet} alt={altText} />
    </ClickableDiv>
  ) : scrollTarget ? (
    <div
      style={{cursor: 'pointer'}}
      onClick={() => mfbScrollSmooth(scrollTarget)}
    >
      <Picture imageSet={imageSet} alt={altText} />
    </div>
  ) : (
    <Picture imageSet={imageSet} alt={altText} />
  );

export default SimpleJumbotron;
