import {Jumbotron, mapAlignment} from '@mfb/lego';
import * as React from 'react';

import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import JumbotronContentModel from './JumbotronContentModel';

const ContentfulJumbotron: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as JumbotronContentModel;

  const headerImage = model.backgroundImage && {
    small: model.backgroundImage.small && model.backgroundImage.small.file.url,
    medium:
      model.backgroundImage.medium && model.backgroundImage.medium.file.url,
    large: model.backgroundImage.large && model.backgroundImage.large.file.url,
  };

  const button = model.actionButton && mapActionButton(model.actionButton);

  return (
    <Jumbotron
      id={model.id}
      title={model.title}
      logo={model.logo && model.logo.file.url}
      description={
        model.childContentfulJumbotronSubtitleTextNode &&
        model.childContentfulJumbotronSubtitleTextNode.subtitle
      }
      img={headerImage}
      button={button}
      minHeight={model.minHeight}
      backgroundColor={getColor(model.backgroundColor)}
      textColor={getColor(model.textColor)}
      alignment={mapAlignment(model.alignment)}
      splitOnMobile={model.useAlternateMobileLayout}
    />
  );
};

export default ContentfulJumbotron;
