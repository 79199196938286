import {RecipeWeeks} from '@mfb/cookbook';
import {Brand} from '@mfb/lego';
import {graphql, StaticQuery} from 'gatsby';
import {flatten} from 'lodash';
import * as React from 'react';
import {findBySkuOrItemumber} from '../../customGraphQlModels/ProductDbModel';
import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {getColor} from '../color/ColorContentModel';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {AllProductsQueryModel} from '../productCard/AllProductsQueryModel';
import RecipeCarouselContentModel from './RecipeCarouselContentModel';
import { RecipeCollectionModel } from '../../customGraphQlModels/ProductRecipeCollectionModel';

const ContentfulRecipeCarousel: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as RecipeCarouselContentModel;

  return (
    <StaticQuery
      query={graphql`
        query {
          allProductDb {
            edges {
              node {
                ...ProductDbFragment
              }
            }
          }
          site {
            ...SiteMetadataFragment
          }
        }
      `}
      render={(data: AllProductsQueryModel) => {
        const product = findBySkuOrItemumber(data.allProductDb, model.sku);

        const productName =
          (product &&
            product.recipeCollections &&
            product.recipeCollections.length > 0 &&
            product.recipeCollections[0].productName) ||
          '';

        const recipeCollections =
          product &&
          product.recipeCollections &&
          product.recipeCollections.length > 0 &&
          product.recipeCollections;

        if (product?.brand === Brand.RMM) {
          const mapRecipeCollections =
            recipeCollections &&
            (recipeCollections
              .map((x, idx) => {
                if (idx !== 0) {
                  return x.collections[0];
                }
              })
              .filter(x => x !== undefined) as Array<RecipeCollectionModel>);

          return (
            <>
              <div className="w-100 mx-auto" style={{maxWidth: '1600px'}}>
                <h1 className="display-3 w-100 mx-auto text-center">
                  {"What we're serving up"}
                </h1>
                <div className="w-100 d-flex flex-column align-items-center justify-content-center p-5 pl-2">
                  {mapRecipeCollections && mapRecipeCollections.length > 0 && (
                    <RecipeWeeks
                    key={product.id}
                    id={product.id}
                    title={product.name}
                    showWeekSelector={false}
                    recipeCollections={mapRecipeCollections}
                    isRecipeModalEnabled={true}
                    selectedDate={mapRecipeCollections && mapRecipeCollections[0]?.date.toString()}
                  />
                  )}
                </div>
              </div>
            </>
          );
        }


        const getRecipesForCarousel = ()=>{
            if(!recipeCollections || recipeCollections.length === 0){
                return;
            }

            const upcomingWeekRecipeCollection = recipeCollections[1];
            const hasUpcomingWeekRecipes = upcomingWeekRecipeCollection.collections?.map(c=> c.recipes.length > 0).some(c=> c);

            if(hasUpcomingWeekRecipes){
                return upcomingWeekRecipeCollection.collections.map(c => {
                    c.recipes = c.recipes.filter(r => r.surcharge === 0);
                    return c;
                });
            }

            const collectionWithRecipes = recipeCollections.find(c=>c.collections.find(c=> c.recipes.length > 0));
            return collectionWithRecipes?.collections?.map(c => {
                c.recipes = c.recipes.filter(r => r.surcharge === 0);
                return c;
            });
        }

        const recipeCollection = getRecipesForCarousel();
        return (
         recipeCollection &&
         recipeCollection.length > 0 && (
            <RecipeWeeks
              id={model.id}
              title={
                model.autoGenerateTitle ? productName : model.titleOverride
              }
              backgroundColor={getColor(model.backgroundColor)}
              recipeCollections={flatten(recipeCollection)}
              button={model.button && mapActionButton(model.button)}
              showWeekSelector={!model.hideWeekSelector}
              isRecipeModalEnabled={true}
              selectedDate={recipeCollection && recipeCollection[0]?.date.toString()}
            />
          )
        );
      }}
    />
  );
};

export default ContentfulRecipeCarousel;
