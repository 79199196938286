import {uniq} from 'lodash';
import * as React from 'react';

import InstagramSection from '../../components/instagram/InstagramSection';
import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {InstagramSectionContentModel} from './InstagramSectionContentModel';

const ContentfulInstagramSection: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as InstagramSectionContentModel;
  const regexp = new RegExp('/p/([^/]+)/');
  const uniqueLinks =
    model.postLinks && model.postLinks.length >= 4 && uniq(model.postLinks);
  const allShortCodes =
    uniqueLinks &&
    uniqueLinks
      .map(t => {
        const result = regexp.exec(t);
        const code = result && result[result.length - 1];

        return code || '';
      })
      .filter(t => t !== '');

  const takeIndex = 4;
  const shortCodes =
    allShortCodes && allShortCodes.length >= takeIndex
      ? allShortCodes.slice(0, takeIndex)
      : [];

  return (
    <InstagramSection
      id={model.id}
      profileHandle={model.profileHandle}
      shortCodes={shortCodes}
      title={model.title}
      iconUrl={model.icon && model.icon.file.url}
    />
  );
};

export default ContentfulInstagramSection;
