import {ProductCard, ProductModel} from '@mfb/cookbook';
import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';

import {ContentfulBaseProps} from '../ContentfulBaseProps';
import {AllProductsQueryModel} from '../productCard/AllProductsQueryModel';
import {getProductModel} from './getProductModel';
import {ProductContentModel} from './ProductContentModel';
import styled from 'styled-components';

const StyledContainer = styled.div`
  margin: 0 8px 24px 8px;
  width: 326px;
`;

const ContentfulProduct: React.FunctionComponent<ContentfulBaseProps> = ({
  baseModel,
}) => {
  const model = baseModel as ProductContentModel;

  return (
    <StaticQuery
      query={graphql`
        query {
          allProductDb {
            edges {
              node {
                ...ProductDbFragment
              }
            }
          }
          site {
            ...SiteMetadataFragment
          }
        }
      `}
      render={(data: AllProductsQueryModel) => {
        const product: ProductModel | undefined = getProductModel(
          model,
          data.allProductDb,
          data.site.siteMetadata.gatewayUrl
        );

        if (!product) {
          return null;
        }

        let cardContent = null;

        switch (product.variant) {
          case 'productCard':
          case 'productCardHighlighted':
            cardContent = (
              <StyledContainer>
                <ProductCard {...product} variant={product.variant} />
              </StyledContainer>
            );
            break;
          default:
            cardContent = <ProductCard {...product} variant={undefined} />;
            break;
        }

        return cardContent;
      }}
    />
  );
};

export default ContentfulProduct;
